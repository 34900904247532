@import "../../utils/variables/variables.scss";

.siteHeader {
  position: relative;
  z-index: 1000;
  .topBar {
    background: $mainBlue;
    padding: 4px 5% 4px 5%;
    .langSelct {
      border: solid 1px rgba($color: $white, $alpha: 1);
      border-radius: $Radius;
      position: relative;
      padding: 6px 10px;
      width: 124px;
      input::placeholder {
        color: $white;
        font-family: $D-DIN-REGULAR;
        font-size: 12px;
        // line-height: 13px;
        // background-image: url(../../img/british.svg);
        background-repeat: no-repeat;
        padding-left: 25px;
        background-position: center left;
      }
    }
    .arabic {
      input::placeholder {
        background-image: url(../../img/egypt.svg);
      }
    }
    .english {
      input::placeholder {
        background-image: url(../../img/british.svg);
      }
    }
    .langSelct:after {
      content: "";
      display: block;
      position: absolute;
      right: -2px;
      top: 50%;
      transform: translateY(-25%);
      width: 20px;
      height: 8px;
      background: url(../../img/down-white.svg);
      background-size: contain;
      background-repeat: no-repeat;
    }
    .socialIcons {
      ul {
        display: flex;
        list-style: none;
        padding-inline-start: 0;
      }

      a {
        margin-right: 25px;
        font-size: 20px;
        color: $white !important;
      }
    }
  }
  .snow {
    position: relative;
    // background-color: #0000006b;
    border-radius: $Radius;
    padding: 2px 20px 2px 10px;
    .degree {
      font-family: "D-DIN" !important;
      font-size: 22px;
      line-height: 38px;
      margin: 0 0 0 8px;
      color: $white;
      z-index: 9;
    }
    .boxwrap {
      .time {
        margin-left: 20px;
        font-size: 22px;
        color: $white;
        position: relative;
        line-height: 24px;
      }
      // .time::before {
      //   content: "";
      //   position: absolute;
      //   top: 0;
      //   left: -10px;
      //   width: 2px;
      //   height: 100%;
      //   background-color: $white;
      // }
    }
  }

  .topBarRight {
    padding: 0 0px 0 25px;
    z-index: 9;
    ul {
      display: flex;
      list-style: none;
      padding-inline-start: 0;

      a {
        margin-right: 24px;
      }
    }
  }

  .book {
    a {
      background: $mainRed;
      padding: 10px 20px;
      text-decoration: none;
      color: $white;
      font-size: 16px;
      border-radius: $Radius;
      font-family: "D-DIN-Bold" !important;
    }
  }

  .menu {
    ul {
      display: flex;
      list-style: none;
      padding-inline-start: 0;

      &:lang(ar) {
        flex-direction: row-reverse;
      }
      li {
        a {
          text-decoration: none;
          font-size: 18px;
          margin-left: 50px;
          display: inline-block;
          color: $white;
          transition: ease all 0.3s;
          position: relative;
          font-family: $D-DIN-BOLD;
          line-height: 19px;
        }
        a:hover {
          transform: scale(1.1);
        }
        .dropdown {
          position: relative;
          display: inline-block;
          .dropbtn {
            text-decoration: none;
            font-size: 20px;
            margin-left: 50px;
            padding: 0;
            display: inline-block;
            color: $white;
            border: none;
            cursor: pointer;
            background: none;
            outline: none;
          }
          .dropdownContent {
            display: none;
            position: absolute;
            right: 0;
            background-color: $subwhite;
            border-radius: 6px;
            min-width: 207px;
            padding: 26px;
            box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
            z-index: 1;
            a {
              font-size: 16px;
              margin: 0;
              padding: 10px;
              color: $mainDarkGray;
              &:hover {
                color: $mainRed !important;
              }
              &::after {
                display: none;
              }
            }
          }
          &:hover .dropdownContent {
            display: block;
          }
        }
      }
      li a:after {
        content: "";
        width: 0%;
        height: 2px;
        background-color: transparent;
        display: block;
        bottom: -5px;
        position: absolute;
        transition: all ease 0.3s;
      }
      li:hover a:after {
        background-color: $white;
        width: 100%;
      }
    }
  }

  .headerWrapper {
    position: absolute;
    z-index: 1;
    width: 100%;
    // * {
    //   font-family: "D-DIN-Bold" !important;
    // }
    .header {
      padding: 4px 5% 9px 5%;
    }
  }

  .selectLang {
    font-family: "D-DIN-Bold" !important;
    * {
      font-family: "D-DIN-Bold" !important;
    }
  }
}

@media (max-width: 1199px) {
  .siteHeader {
    position: absolute;
    width: 100%;
    .mobileMenu {
      position: fixed;
      height: 100vh;
      background-color: $subColorBlue;
      width: 100%;
      z-index: 9999;
      .navWrap::before {
        content: "MENU";
        display: inline-block;
        color: $white;
        position: absolute;
        font-size: 116px;
        font-family: "D-DIN-Bold" !important;
        opacity: 0.05;
      }
      .navWrap {
        height: 100%;
        position: relative;
        & > div {
          z-index: 9999;
        }
        ul {
          padding: 0;
          margin: 0;
          height: 60vh;
          display: flex;
          flex-direction: column;
          justify-content: space-around;
          li {
            list-style: none;
            text-align: center;
            a {
              color: $white;
              text-decoration: none;
              font-family: $D-DIN-REGULAR !important;
              font-size: 28px;
              line-height: 30px;
              margin: 0;
              text-transform: uppercase;
            }
            .dropdown {
              position: relative;
              display: inline-block;
              .dropbtn {
                text-decoration: none;
                font-family: "D-DIN" !important;
                font-size: 30px;
                margin: 0;
                padding: 0;
                display: inline-block;
                color: $white;
                border: none;
                cursor: pointer;
                background: none;
                text-transform: uppercase;
                outline: none;
              }
              .dropdownContent {
                display: none;
                position: absolute;
                right: 0;
                background-color: $subwhite;
                padding: 26px;
                border-radius: 6px;
                min-width: 160px;
                box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
                z-index: 1;
                a {
                  font-size: 18px;
                  margin: 0;
                  padding: 5px;
                  display: block;
                  color: $mainDarkGray;
                  &:hover {
                    color: $mainRed !important;
                  }
                  &::after {
                    display: none;
                  }
                }
              }
              &:hover .dropdownContent {
                display: block;
              }
            }
          }
        }
      }
    }
    .upper {
      .close {
        position: fixed;
        // left: 50px;
        right: 50px;
        top: 50px;
        z-index: 9999;
        width: 20px;
      }
      .icons {
        position: fixed;
        top: 50px;
        right: 50px;
        z-index: 9999;
        a {
          display: inline-block;
          margin-left: 25px;
          width: 20px;
        }
      }
    }
    .lowerLeft {
      position: fixed;
      bottom: 50px;
      left: 50px;
      a {
        color: white;
        margin-right: 3vh;
        svg {
          font-size: 18px;
        }
      }
    }
    .langSelct {
      position: fixed;
      bottom: 50px;
      right: 60px;
      color: $white;
    }
    .lowerRight {
      padding: 5px;
      border: 1px solid #fff;
      position: fixed;
      bottom: 50px;
      right: 60px;
      border-radius: 5px;
      color: $white;
      & > div {
        padding: 0;
      }
      &::before {
        display: none;
      }
      svg {
        color: $white;
        position: relative;
        // position: absolute;
        // right: -20px;
      }
    }
    .headerWrapper {
      position: relative;
      z-index: 1000;
      .burger {
        svg {
          font-size: 20px;
        }
      }
      .header {
        padding: 20px 5% 15px 5%;
        .logoMobile {
          max-width: 120px;
        }
      }
      .snow {
        padding: 2px 15px 2px 2px !important;
        max-width: 115px;
        .degree,
        .time {
          font-size: 22px;
          line-height: 38px;
        }
        .time:before {
          top: 4.5px !important;
          height: 65% !important;
        }
      }
    }
    .book {
      a {
        font-size: 14px;
        padding: 10px 12px;
        background: $mainRed;
        text-decoration: none;
        color: $white;
        font-size: 16px;
        border-radius: $Radius;
        font-family: "D-DIN-Bold" !important;
      }
    }
  }
}
html[lang="ar"] {
  .navWrap::before {
    content: "القائمة" !important;
    font-size: 100px !important;
  }
}
html[lang="ar"]:not(.Safari) {
  .navWrap::before {
    font-family: "Cairo" !important;
  }
  .lowerRight {
    font-family: "Cairo";
  }
  .menu {
    ul {
      li {
        a {
          font-family: $Cairo-bold !important;
        }
      }
    }
  }
}
