@import "../../../../utils/variables/variables.scss";

.HeaderText {
  .slick-slide {
    outline: 0;
    width: 168px;
    height: 309px;
    transition: transform 0.5s;
    &.slick-current.slick-active {
      transform: none;
    }
    &.slick-active {
      transform: translateX(-50%);
    }
    &.slick-current + .slick-active {
      transform: translateX(50%);
    }
  }
  padding: 10px;
  p {
    margin: 0;
    text-align: center;
  }
  .subTitle {
    font-size: 16px;
    font-family: "D-DIN";
    color: gray;
    text-transform: uppercase;
    text-align: left;
  }
  .Title {
    font-size: 30px;
    font-family: "D-DIN-Bold";
    color: #ff173d;
    text-transform: uppercase;
    width: 100%;
    position: relative;
    text-align: left;
    font-weight: 900;
  }

  .description {
    font-size: 16px;
    font-family: $DIN-regular;
    color: $mainDarkGray;
    margin-bottom: 50px;
    text-align: left;
    line-height: 23px;
    padding-top: 24px;
  }
  .MuiContainer-root {
    padding-left: 0 !important;
    padding-right: 0 !important;
  }
  @media (min-width: 600px) {
    .MuiContainer-root {
      padding-left: 0px !important;
      padding-right: 0px !important;
    }
  }
}
html[lang="ar"] {
  .Title {
    text-align: right;
    direction: rtl;
  }
  .subTitle {
    text-align: right;
  }
  .description {
    text-align: right;
  }
}
