@import "../../utils/variables/variables.scss";

.packages {
  .comparison {
    margin-bottom: 70px;
    margin-top: 110px;
    & > div {
      position: relative;
    }
    .snow {
      padding: 0;
      > img {
        margin-bottom: 20px;
      }
      h6 {
        font-size: 20px;
        font-family: $FBT-medium;
        line-height: 26px;
        margin: 0;
        margin-left: 20px;
        margin-bottom: 20px;
      }
      .row {
        min-height: 85px;
        max-height: 85px;
        border-top: solid 1px #d4d4d4;
        border-left: solid 1px #d4d4d4;
        border-right: solid 1px #d4d4d4;
        img {
          width: 100%;
          height: 84px;
        }
        .snowDesc {
          margin-left: 10px;
          font-size: 18px;
          line-height: 24px;
          font-family: $DIN-regular;
        }
        > div:first-child {
          display: flex;
          justify-content: space-between;
        }
      }
      .row:last-child {
        border-bottom: solid 1px #d4d4d4;
        border-bottom-left-radius: $Radius;
        border-bottom-right-radius: $Radius;
        position: relative;
      }
      .snowWrap {
        min-height: 260px;
        max-height: 260px;
        display: flex;
        flex-direction: column;
        justify-content: center;
        border: solid 1px #d4d4d4;
        border-bottom: none;
        border-top-left-radius: $Radius;
        border-top-right-radius: $Radius;
      }
    }
    .swiperContainerClass {
      position: unset;
      [class*="showArrows"]:after {
        font-size: 30px;
      }
      [class*="swiper-button-next"] {
        // right: -10px;
        right: 40px;
        visibility: hidden;
      }
      [class*="swiper-button-next active"] {
        visibility: visible;
        position: fixed;
      }
      [class*="swiper-button-prev"] {
        // left: -10px;
        left: 40px;
        visibility: hidden;
      }
      [class*="swiper-button-prev active"] {
        visibility: visible;
        position: fixed;
      }
    }
    .packageSlide {
      border-radius: $Radius;
      border: solid 1px #d4d4d4;
      .header {
        min-height: 260px;
        max-height: 260px;
        padding: 30px 12px 30px 12px;
        display: flex;
        flex-direction: column;
        justify-content: center;
        h2 {
          font-family: $DIN-regular;
          line-height: 28px;
          font-size: 22px;
          margin: 0 0 20px 0;
          text-align: center;
        }
        h6 {
          font-family: $FBT-heavy;
          line-height: 18px;
          font-size: 28px;
          margin: 0;
          text-align: center;
          color: $subColorBlue;
        }
        a,
        button {
          display: inline-block;
          text-align: center;
          width: 100%;
          background: #4a6ee8;
          border-radius: 5px;
          color: #ffffff;
          text-decoration: none;
          font-family: "D-DIN-Bold";
          // padding: 11px 0;
          // font-size: 20px;
          //Coming soon
          padding: 18px 0;
          font-size: 12px;
          // pointer-events: none;
          //Coming soon
          // margin-top: 8vh;
          margin-top: 5vh;
          border: none;
        }
        button {
          background: #d0d0d0 !important;
          color: #7f8186 !important;
          outline: none;
          border: none;
          pointer-events: none;
        }
      }
      .item {
        min-height: 85px;
        max-height: 85px;
        border-top: solid 1px #d4d4d4;
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        svg {
          margin-right: 5px;
        }
        span {
          font-family: $DIN-regular;
          height: 16px;
          display: inline-block;
          line-height: 27px;
          text-align: center;
        }
      }
    }
  }
  .tickets {
    padding: 90px 0 100px 0;
    .ticketsTitles {
      text-align: center;
      margin-bottom: 50px;
      h4 {
        font-size: 26px;
        font-family: $FBT-medium;
        margin: 0;
        line-height: 35px;
        color: $subColorBlue;
      }
      h2 {
        font-size: 45px;
        font-family: $FBT-medium;
        margin: 0;
        line-height: 60px;
        color: $mainBlue;
        width: fit-content;
        width: -moz-fit-content;
        margin: 0 auto;
        border-bottom: solid;
      }
    }
    .cardsWrap {
      .card {
        text-align: center;
        & > div {
          background-color: #fcfcfc;
          padding: 50px 25px;
          border-radius: $Radius;
          -webkit-box-shadow: 5px 8px 10px 0px rgba(48, 48, 48, 0.1);
          -moz-box-shadow: 5px 8px 10px 0px rgba(48, 48, 48, 0.1);
          box-shadow: 5px 8px 10px 0px rgba(48, 48, 48, 0.1);
          height: 100%;
        }
        .title {
          font-size: 32px;
          margin: 0;
          line-height: 43px;
          color: $subColorBlue;
          text-transform: uppercase;
          font-family: $FBT-heavy;
        }
        .age {
          font-size: 20px;
          margin: 0;
          line-height: 26px;
          font-family: $DIN-regular;
          color: $mainDarkGray;
        }
        .price {
          font-size: 45px;
          margin: 20px 0 20px 0;
          line-height: 60px;
          font-family: $FBT-heavy;
          color: $subColorBlue;
          span {
            font-size: 20px;
          }
        }
        .description {
          font-size: 16px;
          margin: 0;
          line-height: 20px;
          font-family: $DIN-regular;
          color: $mainDarkGray;
          .whiteSvg svg {
            fill: #fff;
            width: 50px;
            height: 50px;
            margin-top: 35px;
            margin-left: 15px;
            margin-bottom: -10px;
            & g {
              fill: $white;
            }
          }
          .blueSvg svg {
            fill: $subColorBlue;
            width: 50px;
            height: 50px;
            margin-top: 35px;
            margin-left: 15px;
            margin-bottom: -10px;
            & g {
              fill: $subColorBlue;
            }
          }
          .meltBlue svg {
            height: 120px !important;
            width: 200px;
            margin-top: 5px;
            margin-bottom: -50px;
            fill: $subColorBlue !important;
            & * {
              fill: $subColorBlue !important;
            }
          }
          .meltWhite svg {
            height: 120px !important;
            width: 200px;
            margin-top: 5px;
            margin-bottom: -50px;
            fill: $white !important;
            & * {
              fill: $white !important;
            }
          }
          ul {
            list-style: none;
            margin: 5px 0 15px 0;
            padding: 0;
            img {
              transform: translateY(27px);
            }
            li {
              margin-top: 24px;
            }
          }
          span {
            font-family: $DIN-bold;
            display: inline-block;
            transform: translateY(15px);
          }
        }
        .book {
          display: inline-block;
          width: 100%;
          background: $subColorBlue;
          padding: 11px 0;
          border-radius: $Radius;
          color: $white;
          text-decoration: none;
          font-family: $DIN-bold;
          font-size: 20px;
          margin-top: 8vh;
        }
        .note {
          font-family: $DIN-regular;
          font-size: 14px;
          margin: 15px 0 0 0;
          line-height: 16px;
          color: $mainRed;
        }
      }
      .card:nth-child(2) {
        & > div {
          background-color: $subColorBlue;
          & * {
            color: $white;
          }
        }
        .book {
          background-color: $white;
          color: $subColorBlue;
        }
      }
      .card:first-child,
      .card:last-child {
        position: relative;
      }
      .card:first-child:after,
      .card:last-child:after {
        content: "";
        position: absolute;
        bottom: 0;
        right: 0;
        width: 174px;
        height: 195px;
        background-image: url(../../img/circle.png);
        background-repeat: no-repeat;
      }
    }
  }
  .snowMuchWrap {
    margin-bottom: 100px;
    // background-color: $AnotherSubGray;
    .snowMuch {
      margin-top: 85px;
    }
    .title h2 {
      text-align: center;
      padding: 15px 20px;
      background: $white;
      font-family: "D-DIN-Bold";
      font-size: 45px;
      line-height: 60px;
      color: $subColorBlue;
      position: relative;
      width: fit-content;
      margin: 30px auto;
      margin-top: 0;
      position: relative;
      border-bottom: solid;
      padding-bottom: 15px;
      width: fit-content;
    }
    .title h2:after {
      content: "";
      background: url(../../img/eclipse.svg);
      position: absolute;
      display: block;
      width: 36px;
      height: 36px;
      background-repeat: no-repeat;
      background-size: cover;
      bottom: -18px;
      left: calc(50% - 18px);
      background-color: #f8f8f8;
      border-radius: 50%;
    }
    // .title:before{
    //     content: '';
    //     display: block;
    //     position: absolute;
    //     top: 0;
    //     left: 33%;
    //     width: 33%;
    //     border: solid 3px $mainRed;
    //     border-radius: $Radius;
    // }
    .description {
      text-align: center;
      font-family: $DIN-regular;
      font-size: 20px;
      line-height: 26px;
      color: $mainDarkGray;
      margin-bottom: 50px;
    }
  }
  .littleWrap {
    background-color: $AnotherSubGray;
    padding-top: 25px;
    padding-bottom: 100px;
  }
  .membershipWrap {
    background-color: $AnotherSubGray;
    padding-top: 30px;
    padding-bottom: 100px;
  }
}
.alpineWrap {
  padding: 30px 0 100px 0;
}
@media (min-width: 1200px) {
  .pricing {
    .titleMobile {
      display: none;
    }
    .extras {
      .price {
        text-align: right;
      }
    }
  }
}
@media (max-width: 1199px) {
  .comparison {
    h6 {
      line-height: 24px !important;
    }
  }
  .packages {
    .tickets {
      padding-top: 50px;
      .ticketsTitles {
        margin-bottom: 30px;
        h2 {
          font-size: 35px;
          line-height: 45px;
        }
      }
      .cardsWrap {
        .card {
          h5 {
            font-size: 26px !important;
          }
        }
      }
    }
  }
}
@media screen and (max-width: 767px) {
  .snowMuchWrap {
    .snowMuch {
      img {
        min-height: 224px;
        object-fit: cover;
      }
    }
    .title {
      margin-top: -5px !important;
      font-size: 26px !important;
      line-height: 30px !important;
    }
    .title::before {
      width: 50% !important;
      left: 25% !important;
    }
    .description {
      font-size: 16px !important;
    }
    h2 {
      font-size: 20px !important;
      line-height: 40px !important;
    }
  }
  .comparison {
    h6 {
      line-height: 24px !important;
    }
  }
  .snow {
    .snowWrap {
      min-height: 262px !important;
    }
    .row {
      .snowDesc {
        font-size: 16px !important;
      }
      img {
        height: 100% !important;
        object-fit: cover;
        width: auto !important;
        max-height: 84px;
      }
    }
  }
}
html[lang="ar"] {
  .packages {
    .header {
      h6 {
        direction: rtl;
      }
    }
    .snow {
      text-align: right;
      direction: rtl;
      h6 {
        margin-right: 20px;
      }
    }
    .snowDesc {
      margin-right: 15px;
      font-family: "Cairo" !important;
    }
    .item {
      direction: rtl;
      svg {
        margin-left: 5px;
      }
    }
    .description {
      font-family: "Cairo";
    }
  }
}
