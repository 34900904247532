@import "../../../../utils//variables/variables.scss";

.containerExperience {
  margin: 100px 0 30px 0;
  .containerData {
    margin-bottom: 66px;
    .containerHeader {
      p {
        margin: 0;
        color: $mainBlue;
        font-size: 28px;
        line-height: 30px;
        font-family: "D-DIN-Bold";
      }
    }
    .containerDescription {
      font-family: $DIN-regular;
      font-size: 14px;
      color: $mainDarkGray;
      line-height: 26px;
      .readMoreBtn {
        color: $mainBlue;
        font-size: 11px;
        font-family: $FBT-medium;
      }
    }
  }
  .containerVideo {
    margin-bottom: 66px;
  }
}
@media only screen and (min-width: 1280px) {
  .containerExperience {
    .containerData {
      .containerHeader {
        p {
          color: $mainBlue;
          font-size: 42px;
          line-height: 60px;
          font-family: "D-DIN-Bold";
        }
      }
    }
    .containerVideo {
      max-height: 376px;
      cursor: pointer;
      border-radius: 12px;
    }
  }
}
@media screen and (max-width: 768px) {
  .containerExperience {
    margin: 53px 0 30px 0;
    .flexContainer {
      flex-direction: column-reverse;
    }
    .containerData {
      text-align: center;
    }
    .containerHeader {
      margin-bottom: 20px;
    }
    .containerVideo {
      margin-bottom: 0;
    }
  }
}
html[lang="ar"] {
  .containerData {
    text-align: right;
    .containerDescription {
      margin-top: 10px;
      font-family: "Cairo";
    }
  }
}
