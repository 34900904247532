@import './utils/variables/variables.scss';
body {
    overflow-x: hidden; }
.packages {
    .swiper-container-horizontal {
        >.swiper-pagination-bullets {
            bottom: 0 !important; } }

    .swiper-container {
        .swiper-pagination-bullet {
            border-color: #cccccc !important;
            transform: translateY(0) !important; } } }

.whiteHeader {
    position: relative !important;
    ul li a {
        color: $mainDarkGray !important;
        &::after {
            background-color: $mainDarkGray !important; } }
    ul li button {
        color: $mainDarkGray !important; }
    .burger {
        svg {
            color: #000; } } }
#swiperHome .swiper-container .swiper-wrapper .swiper-slide {
    overflow: hidden; }
#swiperHome .swiper-pagination {
    text-align: right;
    width: auto;
    right: 132px;
    bottom: 50px;
    z-index: 10; }
#swiperHome .swiper-pagination-bullet {
    position: relative;
    border: none !important;
    background: none !important;
    margin: 0 8px; }
#swiperHome .swiper-pagination-bullet:not(.swiper-pagination-bullet-active):after {
    content: '';
    width: 16px;
    height: 16px;
    background-size: contain;
    background-repeat: no-repeat;
    position: absolute;
    bottom: 0px;
    left: 0;
    border: 2px solid $white;
    border-radius: 50%; }
#swiperHome .swiper-pagination-bullet-active:after {
    content: '';
    background-image: url(./img/snow-flake.svg) !important;
    width: 18px;
    height: 18px;
    background-size: contain;
    background-repeat: no-repeat;
    position: absolute;
    bottom: 0px;
    left: 0;
    border: 2px solid $white;
    border-radius: 50%; }
.swiper-container {
    .swiper-wrapper {
        min-height: inherit;
        .swiper-slide {
            position: relative;
            min-height: inherit;
            // img
            //     min-height: inherit
 } }            //     object-fit: cover
    .swiper-pagination-bullet {
        transform: translateY(-10px);
        border: solid 2px $white !important;
        background: none !important;
        border-radius: 50% !important;
        width: 15px !important;
        height: 15px !important;
        opacity: 1 !important; }
    .swiper-pagination-bullet-active {
        background: $mainRed !important; } }
.swiper-scrollbar {
    display: none; }
.landing .swiper-container {
    height: 80vh; }
.landing .swiper-wrapper {
    .swiper-slide {
          &:after {
                  content: "";
                  position: absolute;
                  backdrop-filter: blur(10px) opacity(0.98) brightness(0.8);
                  width: 100%;
                  height: 214px;
                  bottom: 0;
                  z-index: 2;
                  left: 0; } } }
.landing .swiper-pagination {}
.landing .swiper-slide {
    position: relative; }

.MuiMenuItem-root {
    font-family: 'D-DINExp' !important; }
.MuiInput-underline:after {
    display: none; }
.hideArrows {
    display: none !important; }
.showArrows {
    display: block !important; }
.loader {
    background: #ffffff;
    z-index: 99999;
    width: 100vw;
    position: fixed;
    img {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%); } }
.gallery-thumbs {
    margin-top: 40px;
    .swiper-slide {
        text-align: center;
        cursor: pointer;
        img {
            border: solid 2px transparent;
            padding: 3pxb; } }
    .swiper-slide-thumb-active {
        img {
            border: solid 2px $mainRed;
            padding: 3px;
            border-radius: 50%; } }
    .swiper-button-next {
        color: #000;
        transform: translateX(10px); }

    .swiper-button-prev {
        color: #000;
        transform: translateX(-10px); }
    .swiper-button-next:after , .swiper-button-prev:after {
        display: none;
        img {
            width: 25px;
            object-fit: cover; } } }
.aboutSlider {
    .slider-wrapper {
        margin-bottom: 20px; }
    .swiper-pagination-bullet {
        background: $mainRed !important; }
    .swiper-container {
        padding-bottom: 70px; }
    .swiper-pagination {
        transform: translateY(5px); }
    .swiper-button-next {
        position: absolute;
        top: 96.5%;
        color: #000; }
    .swiper-button-prev {
        position: absolute;
        top: 96.5%;
        color: #000; }
    .swiper-button-next:focus, .swiper-button-prev:focus {
        outline: none !important; }
    .showArrows {
        &::after {
            font-size: 28px;
            font-weight: 600; } } }
.penguins {
    .swiper-container {
        padding-bottom: 70px;
        .swiper-pagination-bullet {
            border-color: #000 !important;
            width: 14px !important;
            height: 14px !important; }
        .swiper-pagination-bullet-active {
            border-color: $mainRed !important; } } }
#swiperPenguin .swiper-pagination-bullet {
    position: relative;
    border: none !important;
    background: none !important;
    margin: 0 9px; }
#swiperPenguin .swiper-pagination-bullet:not(.swiper-pagination-bullet-active):after {
    content: '';
    background-image: url(./img/bluesnowflake.svg) !important;
    width: 24px;
    height: 24px;
    background-size: contain;
    background-repeat: no-repeat;
    position: absolute;
    top: 0;
    left: 0; }
#swiperPenguin .swiper-pagination-bullet-active:after {
    content: '';
    background-image: url(./img/bluesnowflakeActive.svg) !important;
    width: 24px;
    height: 24px;
    background-size: contain;
    background-repeat: no-repeat;
    position: absolute;
    top: 0;
    left: 0; }
#swiperPenguin .showArrows {
    transform: translateY(-80px);
    color: $mainDarkGray !important; }
#swiperPenguin .showArrows:after {
    font-size: 35px !important; }

#swiperTrainers .swiper-button-next, #swiperTrainers .swiper-button-prev {
    color: $mainDarkGray !important;
    top: 35%; }
#swiperTutorial .swiper-button-next, #swiperTutorial .swiper-button-prev {
    color: $mainDarkGray !important; }
.packagesWrap .swiper-button-next, .packagesWrap .swiper-button-prev {
	color: $mainRed !important; }
.packagesWrap .swiper-button-next, .packagesWrap .swiper-button-prev {
    position: relative;
    display: inline-block !important;
    left: 0 !important;
    margin-top: 30px;
    margin-left: 38px; }
.packagesWrap .swiper-button-prev {
    margin-left: 140px; }
.packagesWrap .swiper-button-next:after, .packagesWrap .swiper-button-prev:after {
    font-size: 26px !important; }
#swiperTrainers .swiper-button-next:after, #swiperTrainers .swiper-button-prev:after {
    font-size: 26px !important; }
#swiperTutorial .swiper-button-next:after, #swiperTutorial .swiper-button-prev:after {
    font-size: 26px !important; }
#swiperTutorial .swiper-container {
    height: 310px;
    video {
        transform: translateY(-30px); } }
.happyWrap .happyHours {
    margin-top: 20px !important; }
.activeIcon {
    svg {
        fill: $mainRed !important;
        & * {
            fill: $mainRed !important; } } }
#offersPopup {
    .showArrows {
        color: $white !important;
        width: 15px;
        &::after {
            font-size: 32px !important; } } }
#rulesPopup {
    background-color: #ffffff;
    overflow-y: auto;
    padding: 35px 20px;
    height: 80vh;
    color: #404040;
    border-radius: 6px;
    box-shadow: 2px 2px 5px 1px #404040;
    p.containerHeader {
        color: #404040; } }
.alpineGridSlider {
    .showArrows {
        color: $white !important; } }
.privacyPolicy {
    margin-bottom: 8px !important;
    a {
        font-family: "Gotham";
        font-size: 16px;
        line-height: 18px;
        color: #404040;
        text-decoration: none;
        display: inline-block;
        width : 100%; } }
.antiFraud {
    margin-bottom: 8px !important;
    a {
        font-family: "Gotham";
        font-size: 15px;
        line-height: 18px;
        color: #404040;
        text-decoration: none;
        display: inline-block;
        width : 100%; } }
@media ( min-width: 1200px) {
    // .packages
    //     .swiper-container-horizontal
    //         >.swiper-pagination-bullets
    //             display: none
    #swiperPenguin {
        .showArrows {
            display: none !important; } }
    .aboutSlider {
        padding-top: 130px;
        padding-bottom: 90px;
        .swiper-container {
            padding-bottom: 70px; } } }

@media (max-width: 1199px) {
    .home {
        .landing {
            .swiper-container {
                height: 100vh;
                .swiper-slide {
                    img {
                        height: 100vh;
                        object-fit: cover; } } } } }
    .aboutSlider {
        padding-top: 50px;
        padding-bottom: 0px;
        .swiper-container {
            padding-bottom: 100px; }
        .swiper-pagination-bullet {
            margin: 10px 4px !important; } }
    .skiShcool {
        .swiper-button-prev , .swiper-button-next {
            position: absolute;
            top: 40px;
            color: $mainRed; }
        .swiper-button-prev:focus , .swiper-button-next:focus {
            outline: none !important; } } }

@media (max-width: 767px) {
    .closePopup {
        position: absolute;
        top: 15px !important;
        right: 15px !important;
        width: 15px;
        cursor: pointer;
        z-index: 99; }
    .popupFaq, .popupFaqAr {
        padding: 40px 10px !important; }
    .containerBtnSubmit {
        width: 100%;
        .submit {
            width: 100% !important;
            padding: 16px 45px;
            margin-top: 30px !important;
            cursor: pointer;
            background: $subColorBlue;
            color: #ffffff;
            border: none;
            font-weight: 900; } }
    .packagesWrap {
        padding: 0 25px !important; }
    .packagesWrap .swiper-button-next, .packagesWrap .swiper-button-prev {
	    top: 30%; }
    .packagesWrap .swiper-button-prev {
        margin-left: 0px; }
    .packagesWrap .swiper-button-next:after, .packagesWrap .swiper-button-prev:after {
        font-size: 16px !important; }
    .packagesWrap .swiper-button-next, .packagesWrap .swiper-button-prev {
        margin-top: 5px !important;
        margin-left: 0px !important; }
    html[lang='ar'] {
        .landing .swiper-pagination.swiper-pagination-clickable.swiper-pagination-bullets {
            left: auto !important;
            right: 30% !important; } }
    .landing .swiper-pagination.swiper-pagination-clickable.swiper-pagination-bullets {
        bottom: 8px !important;
        left: 20px !important;
        text-align: left !important; }
    .landing .swiper-wrapper {
        .swiper-slide {
            &:after {
                height: 315px;
                border-radius: 12px; } } }
    .MuiContainer-root {
        padding-left: 25px !important;
        padding-right: 25px !important; }
    .aboutSlider .swiper-button-prev {
        display: block;
        left: 0; }
    .aboutSlider .swiper-button-next {
        display: block;
        right: 0; }
    .aboutSlider .swiper-button-next:focus , .aboutSlider .swiper-button-prev:focus {
        outline: none !important; }
    .aboutSlider .swiper-pagination {
        transform: translate(30px, 5px);
        width: 80%; }
    .collapse {
        transform: scale(0);
        height: 0 !important; } }
.MuiPaper-elevation24 {
    box-shadow: none !important; }
.MuiPaper-root.MuiDialog-paper.MuiDialog-paperScrollPaper.MuiDialog-paperWidthLg.MuiPaper-elevation24.MuiPaper-rounded {
    background: transparent !important; }
.closePopup {
    position: absolute;
    top: 35px;
    right: 60px;
    width: 15px;
    cursor: pointer;
    z-index: 99; }
.bookBtn {
    width: 98%;
    background: $white;
    display: inline-block;
    text-align: center;
    padding: 18px 0;
    border-radius: 6px;
    color: $subColorBlue;
    text-decoration: none;
    border: solid 2px $white;
    font-family: $DIN-bold;
    font-size: 12px;
    pointer-events: none; }
.call {
    width: 98%;
    background: none;
    border: solid 2px $white;
    display: inline-block;
    text-align: center;
    padding: 18px 0;
    border-radius: 6px;
    color: $white;
    text-decoration: none;
    font-family: $DIN-bold;
    font-size: 12px; }
.MuiDialogContent-root {
    overflow: hidden !important; }
.bookDisabled {
    //Coming soon
    font-size: 16px !important;
    pointer-events: none;
    padding: 13px 12px !important;
    background-color: $white;
    color: $subColorBlue !important; }
.MuiSelect-select:focus {
    background-color: transpernt !important; }
.closePopupStyleLEFT {
    width: 82px;
    top: 0;
    position: absolute;
    right: 0;
    cursor: pointer; }
.closePopupStyleRTL {
    width: 82px;
    top: 0;
    position: absolute;
    cursor: pointer;
    left: 0; }
.contactFooter {
    background: $subColorBlue;
    border-radius: 6px;
    form.LEFT {
        input,textarea,.errorOccurred {
            text-align: left; } }
    form.RTL {
        input,textarea,.errorOccurred {
            text-align: right; }
        textarea {
            padding-right: 19px; } }
    p {
        font-family: "Gotham-Bold";
        color: $white;
        text-transform: uppercase;
        font-size: 36px;
        margin: 0;
        margin-bottom: 30px;
        position: relative;
        padding-bottom: 25px;
        width: 85%;
        padding-top: 44px;
        &:after {
            content: "";
            position: absolute;
            background: $white;
            width: 107px;
            border: 3px solid #ffff;
            border-radius: 6px;
            bottom: 0; } }
    p.LEFT {
        text-align: left;
        &:after {
            left: 0; } }
    p.RTL {
        text-align: right;
        width: 100%;
        &:after {
            right: 0; } }
    input {
        height: 52px;
        border: none;
        width: 100%;
        font-size: 16px;
        color: #B3B3B3;
        margin-bottom: 16px;
        font-family: $FBT-medium;
        line-height: 52px;
        &::placeholder {
             font-size: 16px;
             color: #B3B3B3;
             font-family: $FBT-medium;
             line-height: 52px; } }
    textarea {
        border: none;
        height: 97px;
        font-family: $FBT-medium;
        line-height: 20px;
        font-size: 16px;
        color: #B3B3B3;
        padding-top: 10px;
        &::placeholder {
             font-size: 16px;
             color: #B3B3B3;
             font-family: $FBT-medium;
             line-height: 20px; } }
    button {
        position: absolute;
        left: 50%;
        transform: translate(-50%,50%);
        color: $subColorBlue;
        background: $white;
        bottom: 0;
        padding: 0;
        height: 52px;
        width: 192px;
        border: none;
        font-size: 20px;
        font-family: $FBT-heavy;
        text-transform: uppercase;
        cursor: pointer;
        border-radius: 12px;
        &:disabled {
            background: #b5b5b5;
            color: #3a3a3a;
            cursor: none; } }
    .errorOccurred {
        color: $mainRed;
        font-size: 18px;
        font-family: 'Gotham-Medium'; }
    .thankYou {
        color: #4BB543;
        font-size: 18px;
        font-family: "Gotham-Bold";
        text-align: center;
        padding-top: 10px; } }

#contactPopup {
    background: $white !important;
    padding: 45px 45px;
    border-radius: 6px;
    form.LEFT {
        input,textarea {
            text-align: left; } }
    form.RTL {
        input,textarea {
            text-align: right; } }
    p {
        font-family: "Gotham-Bold";
        font-size: 26px;
        line-height: 35px;
        color: $white;
        padding: 10px 0;
        text-align: center;
        padding-top: 0;
        margin: 0;
        margin-bottom: 30px; }
    .or {
        color: $mainDarkGray;
        font-family: "Gotham-Bold";
        font-size: 20px;
        padding: 10px;
        margin: 0; }
    .containerBtnSubmit {
        .submit {
            padding: 16px 45px;
            margin-top: 15px;
            cursor: pointer;
            background-color: $subColorBlue !important;
            color: #ffffff;
            border: none;
            font-weight: 900;
            text-align: center; }
        .thankYou {
            color: #4BB543;
            font-size: 18px;
            font-family: "Gotham-Bold";
            text-align: center;
            padding-top: 10px; }
        .errorOccurred {
             @extend .thankYou;
             color: $mainRed; }
        .callUs-btn {
            padding: 16px 45px;
            cursor: pointer;
            background-color: $mainRed !important;
            border: none;
            font-weight: 900;
            text-align: center;
            border-radius: $Radius;
            display: inline-block;
            & a {
             color: $white;
             text-decoration: none; } } } }
#form-dialog-title {
    display: none !important; }
.popupFaqAr {
    text-align: right;
    div {
        div {
            flex-direction: row-reverse; } } }
.popupFaq,.popupFaqAr {
    // width: 840px
    background: #fff;
    padding: 40px 100px;
    border-radius: $Radius;
    overflow-y: scroll;
    height: 75vh;
    h2 {
        text-align: center;
        color: $subColorBlue;
        margin-bottom: 30px;
        font-family: 'Gotham-Bold'; }
    .MuiPaper-elevation1 {
        box-shadow: none !important;
        background: $subColorBlue;
        color: #fff;
        margin-bottom: 16px;
        border-radius: $Radius;
        font-family: 'D-DIN'; }
    .MuiExpansionPanel-root:before {
        display: none !important; }
    .MuiExpansionPanelSummary-content {
        margin: 0 !important; }
    .MuiExpansionPanelSummary-root {
        padding: 0px 25px !important; } }
#skiClupProgram {
    max-width: 848px;
    padding: 0 10px;
    background: white;
    border-radius: 5px;
    .bgContent {
        position: relative; }
    .bgContent:after {
        content: "";
        background: url("./img/textureOne.png");
        position: absolute;
        width: 56%;
        height: 50%;
        top: 0;
        right: 0;
        background-position: center;
        background-repeat: no-repeat;
        background-size: cover; }
    .bgContent:before {
        content: "";
        background: url("./img/textureTwo.png");
        position: absolute;
        width: 33%;
        height: 20%;
        bottom: 0;
        left: 0;
        background-position: center;
        background-repeat: no-repeat;
        background-size: cover; }
    .popupSkiClupContentContainer {
        padding-top: 72px; }
    .subTitle {
        font-size: 20px;
        color: #4A6EE8;
        text-transform: uppercase;
        font-family: $FBT-medium;
        line-height: 22px;
        margin: 0; }
    .title {
        font-size: 36px;
        color: $mainBlue;
        text-transform: uppercase;
        font-family: $FBT-heavy;
        margin: 0;
        margin-bottom: 25px; }
    .content {
        font-size: 20px;
        color: #404040;
        font-family: $DIN-regular;
        line-height: 29px;
        margin: 0; }
    .subContent {
        font-size: 20px;
        color: #707070;
        font-family: $FBT-heavy;
        line-height: 24px;
        display: block; }
    .registerBtn {
        background: $subColorBlue;
        font-size: 18px;
        font-family: $FBT-heavy;
        padding: 18px 0;
        width: 70%;
        outline: none;
        border-radius: $Radius;
        border: none;
        margin-top: 45px;
        position: relative;
        text-transform: uppercase;
        a {
           color: $white;
           text-decoration: none; } } }
@media screen and ( max-width: 900px) {
    .MuiDialogContent-root {
        overflow: auto !important; }
    .bookBtn, .call {
        font-size: 12px !important;
        width: 90% !important; }
    .gif {
        height: 450px !important; }
    .popupFaq {
        // width: 280px !important
        padding: 37px 14px !important; } }

html[lang=ar]:not(.Safari) {
    h1,h2,h3,h4,h5,h6 {
        font-family: 'Cairo-Bold',Arial, Helvetica, sans-serif !important; }
    p,li,span,a,input,button {
        font-family: 'Cairo',Arial, Helvetica, sans-serif !important; }
    .MuiListItem-root {
        direction: rtl; }
    .MuiSnackbarContent-message {
        font-family: 'Cairo',Arial, Helvetica, sans-serif !important;
        direction: rtl; }
    #swiperHome .swiper-pagination {
        left: 132px !important;
        right: auto; } }
.gif {
    width: 100%;
    height: 440px;
    object-fit: cover; }
.chevron img {
    transform: rotate(0deg) !important; }

.MuiSvgIcon-root {
    color: #ffffff; }

html[lang=ar]:not(.Safari) textarea {
    font-family: 'Cairo',Arial, Helvetica, sans-serif !important; }
@media (max-width: 768px) {
    #skiClupProgram {
        .bgContent:after {
           display: none; }
        .bgContent:before {
           display: none; }
        .popupSkiClupContentContainer {
           padding-top: 10px; }
        .subTitle {
           font-size: 16px;
           line-height: 22px; }
        .title {
           font-size: 28px; }
        .content {
           font-size: 16px;
           line-height: 29px; }
        .subContent {
           font-size: 16px;
           line-height: 24px; }
        .registerBtn {
           font-size: 16px;
           padding: 18px 0;
           margin-top: 45px; } } }
.popUpSkiClupProgram {
    button {
        border: none;
        pointer-events: none; } }
html[lang=ar]:not(.Safari) {
    .packagesWrap {
        direction: rtl;
        .swiper-button-prev {
            margin-right: 140px;
            margin-left: 38px; } }
    .popupSkiClupContentContainer {
        direction: rtl; } }
#popUpRegisterationProfessionalPackage {
    max-width: 843px;
    .bg {
        background:url('./img/freeStyleNightAccessPopUpBlur.png') {}
        background-position: center;
        background-repeat: no-repeat;
        background-size: cover;
        border-radius: 6px;
        img {
            padding: 29px 22px; } }
    .popupRegisterationProfessionalPackageContentContainer {
        padding-left: 30px;
        .subTitle {
            margin: 0;
            padding: 0;
            font-size: 20px;
            line-height: 27px;
            font-family: $FBT-medium;
            color: $subColorBlue; }
        .title {
            margin: 0;
            padding: 0;
            font-size: 36px;
            line-height: 47px;
            font-family: $FBT-heavy;
            color: $mainBlue;
            text-transform: uppercase;
            margin-bottom: 24px; }
        input {
            width: 100%;
            height: 42px;
            margin-bottom: 15px;
            border-radius: 5px;
            border: 1px solid $mainDarkGray;
            padding-left: 15px;
            font-size: 14px;
            font-family: $FBT-heavy; }
        .error {
            margin-bottom: 5px;
            font-size: 16px;
            font-family: $DIN-regular;
            color: $mainRed; }
        .note {
            font-size: 16px;
            font-family: $DIN-regular;
            line-height: 21px;
            position: relative;
            display: inline-block;
            margin: 0;
            .labelCheckbox {
                display: block;
                margin-left: 20px;
                padding-left: 7px;
                line-height: 21px;
                text-align: left;
                padding-top: 7px;
                &:before {
                    content: "";
                    display: block;
                    position: absolute;
                    width: 21px;
                    height: 21px;
                    top: 0;
                    left: 0;
                    background: transparent;
                    border: 3px solid $subColorBlue;
                    box-sizing: border-box;
                    border-radius: 2px; }
                &:after {
                    display: block;
                    position: absolute;
                    width: 21px;
                    height: 21px;
                    top: 17px;
                    transform: translateY(-50%);
                    left: 0;
                    font-size: 18px;
                    color: #0087b7;
                    line-height: 21px;
                    text-align: center; } }
            input[type="checkbox"] {
                opacity: 0;
                z-index: 1;
                position: absolute;
                width: 21px;
                height: 21px;
                margin: 0; }
            input[type="checkbox"]:checked + .labelCheckbox::after {
                content: "\2714";
                color: $subColorBlue; } }
        .register {
            color: $white;
            background: $subColorBlue;
            text-align: center;
            border-radius: 5px;
            width: 100%;
            height: 51px;
            line-height: 51px;
            text-transform: uppercase;
            border: none;
            padding: 0;
            font-size: 18px;
            font-family: $FBT-heavy;
            margin-top: 24px;
            outline: none;
            cursor: pointer;
            position: relative; } }
    .choo {
        position: relative; }
    .choo:after {
        content: "";
        background: url("./img/textureOne.png");
        position: absolute;
        width: 45%;
        height: 36%;
        top: 0;
        right: 0;
        background-position: center;
        background-repeat: no-repeat;
        background-size: cover; }
    .choo:before {
        content: "";
        background: url("./img/textureTwo.png");
        position: absolute;
        width: 33%;
        height: 20%;
        bottom: 0;
        left: 0;
        background-position: center;
        background-repeat: no-repeat;
        background-size: cover; } }


