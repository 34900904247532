@import "../../../../utils/variables/variables.scss";

.HeaderText {
  p {
    margin: 0;
    text-align: center;
  }
  .subTitle {
    font-size: 26px;
    font-family: "D-DIN-Bold";
    line-height: 35px;
    color: $subColorBlue;
    margin-top: 65px;
    text-transform: uppercase;
  }
  .Title {
    font-size: 40px;
    font-family: "D-DIN-Bold";
    line-height: 60px;
    color: $mainBlue;
    text-transform: uppercase;
    // text-decoration: underline;
    border-bottom: solid;
    padding-bottom: 15px;
    width: fit-content;
    margin: 15px auto;
    position: relative;
  }
  .Title:after {
    content: "";
    background: url(../../../../img/eclipse.svg);
    position: absolute;
    display: block;
    width: 36px;
    height: 36px;
    background-repeat: no-repeat;
    background-size: cover;
    bottom: -18px;
    left: calc(50% - 18px);
    background-color: #f8f8f8;
    border-radius: 50%;
  }
  .description {
    margin-top: 20px;
    font-size: 20px;
    font-family: $DIN-regular;
    line-height: 26px;
    color: $mainDarkGray;
    margin-bottom: 50px;
  }
}
@media screen and (max-width: 1199px) {
  .HeaderText {
    .Title {
      font-size: 24px !important;
      line-height: 36px !important;
      margin-bottom: 25px;
    }
    .description {
      font-size: 20px !important;
    }
  }
}
html[lang="ar"] {
  .Title {
    direction: rtl;
  }
}
