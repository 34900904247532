@import "../../../../utils/variables/variables.scss";
.skiClubProgramContainer {
  background-image: url("../../../../img/ski-club-program-bg.jpg");
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  color: $white;
  padding: 80px 0 50px;
  .intro {
    margin: 0;
    font-size: 26px;
    text-transform: uppercase;
    line-height: 35px;
    font-family: $FBT-medium;
  }
  .title {
    margin: 0;
    font-size: 45px;
    text-transform: uppercase;
    line-height: 60px;
    font-family: $FBT-heavy;
    padding-bottom: 30px;
  }
  p {
    font-family: $DIN-regular;
    font-size: 18px;
    line-height: 28px;
    margin: 0;
    .date {
      font-family: $FBT-medium;
      font-size: 22px;
      line-height: 30px;
      display: block;
      padding: 0px 0 20px;
      .line {
        font-family: "D-DIN-Bold";
      }
    }
  }
  .join {
    font-size: 18px;
    font-family: $FBT-heavy;
    line-height: 22px;
    color: #142b73;
    padding: 18px 0;
    width: 90%;
    outline: none;
    border: none;
    border-radius: 6px;
    cursor: pointer;
    background: $white;
  }
  .priceContainer {
    text-align: right;
  }
  .price {
    font-size: 45px;
    display: inline-block;
    font-family: $FBT-heavy;
    line-height: 60px;
    margin: 0;
    text-align: right;
    position: relative;
    &:after {
      position: absolute;
      content: "3,000";
      color: rgba($color: $white, $alpha: 0.5);
      font-size: 20px;
      left: 0;
      top: 0;
      transform: translateY(-50%);
      text-decoration: line-through;
    }
    span {
      font-size: 20px;
      font-family: $FBT-medium;
      line-height: 26px;
    }
  }

  .note {
    font-size: 16px;
    font-family: $DIN-regular;
    line-height: 21px;
    // padding-bottom: 50px;
    position: relative;
    display: inline-block;
    margin-top: 45px;
    .labelCheckbox {
      display: block;
      margin-left: 20px;
      padding-left: 7px;
      line-height: 21px;
      text-align: left;
      padding-top: 7px;
    }
    .labelCheckbox::before {
      content: "";
      display: block;
      position: absolute;
      width: 21px;
      height: 21px;
      top: 0;
      left: 0;
      background: transparent;
      border: 3px solid $white;
      box-sizing: border-box;
      border-radius: 2px;
    }
    .labelCheckbox::after {
      // content: "\2714";
      display: block;
      position: absolute;
      width: 21px;
      height: 21px;
      top: 17px;
      // transform: translateY(-50%);
      left: 0;
      font-size: 18px;
      color: #0087b7;
      line-height: 21px;
      text-align: center;
    }
    input[type="checkbox"] {
      opacity: 0;
      z-index: 1;
      position: absolute;
      width: 21px;
      height: 21px;
      margin: 0;
    }
    input[type="checkbox"]:checked + .labelCheckbox::after {
      content: "\2714";
      color: #fff;
    }
  }
  .smallWarning {
    width: 19px;
    margin-left: 15px;
  }
  input,
  select {
    width: 100%;
    border: none;
    outline: none;
    padding: 16px 0;
    border-radius: 6px;
    padding-left: 19px;
    position: relative;
  }
  select {
    height: 47px;
    padding: 0;
    padding-left: 16px;
  }
  .inputContainer {
    position: relative;
  }
  .errorText {
    display: none;
    p {
      line-height: 1.6;
      font-size: 15px;
      font-family: $DIN-regular;
      color: rgba($color: #404040, $alpha: 0.4);
      margin: 0;
      padding: 20px;
      display: inline-block;
    }
    background-color: #f5f7fa;
    border-radius: 6px;
    text-transform: capitalize;
    // display: inline-block;
    position: absolute;
    top: 0;
    right: 0;
    // transform: translateY(-60%);
  }
  .imgError {
    position: absolute;
    top: 50%;
    // transform: translateY(-50%);
    right: 16px;
    width: 18px;
  }
  .warningIcon {
    &:hover .errorText {
      display: inline-block;
    }
  }
  // select {
  //   // margin-bottom: 45px;
  // }
  // input[type="checkbox"] {
  //   width: 24px;
  //   height: 24px;
  //   visibility: hidden;
  //   border: 3px solid $white;
  //   margin: 0;
  //   margin-right: 5px;
  //   vertical-align: middle;
  // }
  // input[type="checkbox"]:after {
  //   content: " ";
  //   display: inline-block;
  //   color: $white;
  //   width: 100%;
  //   height: 100%;
  //   visibility: visible;
  //   border: 3px solid $white;
  //   border-radius: 3px;
  //   box-sizing: border-box;
  // }

  // input[type="checkbox"]:checked:after {
  //   content: "\2714";
  //   line-height: 24px;
  //   text-align: center;
  //   font-size: 16px;
  // }

  .errorMessage {
    border: 1px solid red;
  }
  .listWarningMessage {
    padding: 0;
    font-family: $DIN-regular;
    font-size: 16px;
    line-height: 26px;
    list-style: none;
    & li::before {
      content: "o";
      padding-right: 10px;
      font-size: 15px;
    }
    .linkToChooseYourLesson {
      cursor: pointer;
      margin: 0;
      text-decoration: underline;
      display: inline-block;
      padding-left: 5px;
      font-size: 16px;
    }
  }
  .skiClupProgramContainerForAnimation {
    // transform: translateX(-70px);
    // opacity: 0;
    transition: 1s;
  }
  .skiClupProgramContainerForAnimation.appear {
    transform: translateX(0px);
    opacity: 1;
  }
  .skiClupProgramContainerForAnimation.remove {
    transform: translateX(-70px);
    opacity: 0;
  }
}
html[lang="ar"] {
  .skiClubProgramContainer {
    background-image: url("../../../../img/ski-club-program-bg-ar.png");
    direction: rtl;
  }
  p {
    .date {
      .line {
        font-family: "D-DIN-Bold" !important;
      }
    }
  }
  input {
    padding-right: 19px;
  }
  .price {
    &:after {
      content: "٣٠٠٠";
    }
  }
  .imgError {
    left: 16px;
    right: unset;
  }
  .errorText {
    right: unset;
    left: 0;
  }
  .priceContainer {
    text-align: left;
  }
}
@media screen and (max-width: 768px) {
  .skiClubProgramContainer {
    .intro {
      padding: 80px 0 0;
      font-size: 20px;
      line-height: 35px;
    }
    .title {
      font-size: 40px;
      line-height: 45px;
    }
    p {
      font-size: 18px;
      line-height: 29px;
      .date {
        font-size: 18px;
        line-height: 30px;
        padding: 30px 0 20px;
      }
    }
    .join {
      font-size: 16px;
      line-height: 22px;
      padding: 18px 0;
      width: 90%;
    }
    .btnContainer {
      text-align: center;
    }
    .priceContainer {
      text-align: center;
      margin-top: 20px;
    }
    .price {
      font-size: 40px;
      line-height: 60px;
      span {
        font-size: 16px;
        line-height: 26px;
      }
    }
    .note {
      font-size: 16px;
      line-height: 21px;
      padding-bottom: 50px;
    }
  }
}
