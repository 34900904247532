@import "../../../utils/variables/variables.scss";
.containerPopup {
  .MuiDialog-paperWidthSm {
    max-width: 514px;
    background-color: $subColorBlue;
    position: relative;
    overflow: visible;
    padding: 0 8px;
    box-shadow: #0000008a 0 3px 6px !important;
    border-radius: 12px;
    padding-bottom: 15px;
  }
}
#form-dialog-title h2 {
  font-family: $FBT-heavy;
  font-size: 26px;
  line-height: 35px;
  color: $mainBlue;
  padding: 10px 0;
  text-align: center;
}
.saveUpPopup {
  background-color: #ffffff !important;
  color: #4a6ee8 !important;
  text-decoration: none !important;
  font-family: "D-DIN-Bold" !important;
  font-size: 20px !important;
  line-height: 22px !important;
  width: 100% !important;
  border-radius: 5px !important;
  border-color: transparent !important;
  margin-top: 50px;
  // opacity: 0;
  // visibility: hidden;
  // transform: translateX(-10%) !important;
  opacity: 1;
  visibility: visible;
  transform: translateX(0) !important;
  transition: ease all 0.3s !important;
  display: inline-block !important;
  cursor: pointer;
  a {
    padding: 15px 75px !important;
    color: #4a6ee8 !important;
    text-decoration: none !important;
    font-family: "D-DIN-Bold" !important;
    font-size: 20px !important;
    line-height: 22px !important;
    width: 100%;
    height: 100%;
    display: inline-block;
  }
}
.save:hover .saveUpPopup {
  // opacity: 1;
  // visibility: visible;
  // transform: translateX(0) !important;
}

.containerGridAll {
  background-color: $subColorBlue;
  border-radius: 6px;
  -webkit-border-radius: 6px;
  -moz-border-radius: 6px;
  -ms-border-radius: 6px;
  -o-border-radius: 6px;
  overflow: hidden;
  margin-bottom: 50px;
  .containerImgRight {
    img {
      width: 100%;
      max-height: 240px;
      object-fit: cover;
      object-position: center;
    }
  }
  .containerData {
    padding: 36px 24px;
    p,
    ul {
      margin: 0;
      color: $white;
    }

    .Header {
      p {
        font-size: 24px;
        line-height: 30px;
        font-family: $FBT-heavy;
        margin-bottom: 10px;
        text-transform: uppercase;
        margin: 0;
      }
    }
    .description {
      p {
        font-family: $DIN-regular;
        font-size: 20px;
        line-height: 28px;
        margin-bottom: 12px;
      }
      ul {
        list-style: none;
        padding: 0 0 0 15px;
        margin-bottom: 15px;
        li {
          font-size: 18px;
          line-height: 18px;
          font-family: $DIN-regular;
          margin-bottom: 12px;
          &::before {
            content: "";
            display: inline-block;
            border: 1px solid $white;
            margin-left: -1em;
            margin-right: 10px;
            width: 5px;
          }
        }
      }
    }
    .PackagePerPerson {
      .Header {
        p {
          font-size: 20px;
          line-height: 28px;
          margin: 0;
          text-transform: uppercase;
        }
      }
      .description {
        p {
          font-size: 32px;
          font-family: $FBT-heavy;
          line-height: 45px;
          color: $white;
          span {
            font-size: 18px;
            font-family: $FBT-medium;
            line-height: 24px;
          }
        }
        .OR {
          font-size: 26px;
          font-family: $FBT-heavy;
          line-height: 38px;
        }
      }
    }
  }
}
.containerHeader {
  width: 100%;
  text-align: center;
  margin-bottom: 25px !important;
  p {
    margin: 0;
    color: $white;
    font-size: 32px;
    line-height: 30px;
    font-family: $FBT-heavy;
    color: #4a6ee8;
    font-weight: bold;
  }
}
.containerData {
  margin-top: 16px;
  span {
    font-family: $DIN-regular;
    font-size: 18px;
    color: $mainDarkGray;
    margin-bottom: 14px;
  }
  .containerUL {
    margin-bottom: 100px;
    li {
      margin-bottom: 20px;
      font-size: 18px;
      line-height: 20px;
      font-family: $DIN-regular;
      &:before {
        content: "";
        display: inline-block;
        margin-top: 9px;
        border-radius: 100%;
        -webkit-border-radius: 100%;
        -moz-border-radius: 100%;
        -ms-border-radius: 100%;
        -o-border-radius: 100%;
      }
    }
  }
}
.rules,
.contact {
  color: $mainDarkGray !important;
  border: none !important;
  text-align: left;
  display: inline-block !important;
  padding: 0 !important;
  font-size: 16px !important;
  font-family: $DIN-regular !important;
  line-height: 18px !important;
  margin-bottom: 8px !important;
  text-transform: capitalize !important;
  width: 100%;
}
.rules:hover,
.contact:hover {
  background-color: transparent !important;
}
.form {
  input,
  textarea {
    font-size: 17px;
    color: $mainDarkGray;
    font-family: Arial;
    padding-left: 10px;
    border: solid 1px $mainDarkGray;
    border-radius: $Radius;
  }
  input {
    height: 35px;
    margin-bottom: 24px;
    width: 98.5%;
  }
  input[type="checkbox"] {
    width: auto;
    position: relative;
    margin-bottom: 0;
    margin-left: 0;
    border: solid 1px #404040;
  }
  label[for="checkBox"] {
    font-size: 17px;
    color: $mainDarkGray;
    font-family: Arial;
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
  }
  textarea {
    height: 70px;
    resize: none;
    width: 100%;
    margin-bottom: 24px;
  }
  & > div:nth-child(even) {
    text-align: right;
    position: relative;
  }
  .error {
    color: red;
    font-family: $DIN-regular;
    font-size: 18px;
    padding-bottom: 10px;
    text-align: left;
  }
}
.submit,
.callUs-btn {
  padding: 8px 24px;
  margin-top: 15px;
  background: none;
  border: solid 1px $mainDarkGray;
  border-radius: 5px;
  font-family: "D-DIN";
  font-size: 18px;
}
.containerGridAll {
  background-color: $subColorBlue;
  border-radius: 6px;
  -webkit-border-radius: 6px;
  -moz-border-radius: 6px;
  -ms-border-radius: 6px;
  -o-border-radius: 6px;
  overflow: hidden;
  margin-bottom: 50px;
  .containerImgRight {
    img {
      width: 100%;
      max-height: 240px;
      object-fit: cover;
      object-position: center;
    }
  }
  .containerData {
    padding: 36px 40px;
    p,
    ul {
      margin: 0;
      color: $white;
    }
    .Header {
      p {
        font-size: 28px;
        line-height: 37px;
        font-family: $FBT-heavy;
        margin-bottom: 24px;
        text-transform: uppercase;
      }
    }
    .description {
      ul {
        list-style: none;
        padding: 0 0 0 18px;
        margin-bottom: 18px;
        li {
          font-size: 18px;
          line-height: 20px;
          font-family: $DIN-regular;
          margin-bottom: 16px;
          &::before {
            content: "";
            display: inline-block;
            border: 1px solid $white;
            margin-left: -1em;
            margin-right: 10px;
            width: 5px;
          }
        }
      }
    }
    .PackagePerPerson {
      .Header {
        p {
          font-size: 20px;
          line-height: 30px;
          margin: 10px 0 0 0;
          text-transform: uppercase;
        }
        span {
          font-family: $DIN-regular;
          color: $white !important;
        }
      }
      .description {
        text-align: center;
        p {
          font-size: 33px;
          font-family: $FBT-heavy;
          line-height: 60px;
          color: $white;
          text-align: initial;
          span {
            font-size: 18px;
            font-family: $FBT-medium;
            line-height: 24px;
            color: $white;
          }
        }
        .OR {
          font-size: 28px;
          font-family: $FBT-heavy;
          line-height: 42px;
        }
      }
    }
  }
}
.containerGridAll:last-child {
  margin-bottom: 0;
}
@media only screen and (min-width: 1280px) {
  .containerGridAll {
    .containerImgRight {
      img {
        min-height: 100%;
        object-fit: cover;
      }
    }
    .containerData {
      padding: 36px 24px;
      p,
      ul {
        margin: 0;
      }
    }
  }
}
@media screen and (max-width: 1199px) {
  .PackagePerPerson {
    .description {
      p {
        font-size: 30px !important;
        line-height: 45px !important;
      }
    }
  }
  .containerImgRight {
    img {
      min-height: 280px;
    }
  }
  .save .saveUpPopup {
    opacity: 1;
    visibility: visible;
  }
}

@media only screen and (min-width: 1280px) {
  .containerGridAll {
    .containerImgRight {
      img {
        min-height: 100%;
        object-fit: cover;
      }
    }
    .containerData {
      padding: 25px 50px;
      min-height: 400px;
      p,
      ul {
        margin: 0;
      }
    }
  }
}
@media screen and (max-width: 1199px) {
  .containerGridAll {
    min-height: 600px;
  }
  .PackagePerPerson {
    .description {
      p {
        font-size: 30px !important;
        line-height: 45px !important;
      }
    }
  }
  .containerImgRight {
    img {
      min-height: 280px;
    }
  }
  .form {
    input,
    textarea {
      width: 100%;
    }
  }
}

@media (max-width: 600px) {
  //     .containerImgRight{
  //         img{
  //             display: none;
  //         }
  //     }
  .containerData {
    .Header {
      p {
        font-size: 22px !important;
      }
    }
    .description {
      li,
      p {
        font-size: 16px !important;
        margin-bottom: 12px !important;
      }
    }
  }
}
html[lang="ar"] {
  .containerGridAll {
    .containerData {
      text-align: right;
      direction: rtl;
      .description ul li::before {
        position: absolute;
        right: 26px;
        transform: translateY(12px);
      }
    }
  }
  .saveUpPopup {
    transform: translateX(0) !important;
  }
}
html[lang="ar"] {
  .containerUL {
    direction: rtl;
  }
  .PackagePerPerson {
    .description {
      p {
        text-align: right;
      }
    }
  }
}

@media (max-width: 767px) {
  .containerPopup {
    .MuiDialog-container.MuiDialog-scrollPaper {
      div {
        .MuiDialogContent-root {
          padding: 0;
          #rulesPopup {
            .containerUL {
              padding: 0;
            }
          }
        }
      }
    }
  }
}
