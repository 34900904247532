@import "../../../../utils/variables/variables.scss";
.containerTripAdvisor {
  background-color: $AnotherSubGray;
  margin-top: 35px;
  .containerBackgroundColor {
    .containerAllGrids {
      .slide {
        // padding: 40px 0;
        div {
          // display: flex;
          // justify-content: center;
          .cardInfo {
            iframe {
              width: 100%;
              height: 100%;
            }
            // box-shadow: 1px 1px 1px #ccc;
            // background: $white;
            // border-radius: 10px;
            // -webkit-border-radius: 10px;
            // -moz-border-radius: 10px;
            // -ms-border-radius: 10px;
            // -o-border-radius: 10px;
            // margin: 3px;
            // position: relative;
            // display: flex;
            // justify-content: center;
            // align-items: center;
            // width: 398px;
            // padding: 40px 16px;
            // .cardImg {
            //   position: absolute;
            //   top: -30px;
            //   left: 16px;
            // }
            // .cardMessage {
            // color: $mainDarkGray;
            // font-size: 16px;
            // font-family: $DIN-regular;
            // max-height: 133px;
            // height: 100px;
            // overflow-y: scroll;
            /* width */
            // &::-webkit-scrollbar {
            //   width: 3px;
            // }

            /* Track */
            // &::-webkit-scrollbar-track {
            //   background: #ffffff;
            //   border-radius: 10px;
            //   -webkit-border-radius: 10px;
            //   -moz-border-radius: 10px;
            //   -ms-border-radius: 10px;
            //   -o-border-radius: 10px;
            // }

            /* Handle */
            // &::-webkit-scrollbar-thumb {
            //   background: #888;
            //   border-radius: 10px;
            //   -webkit-border-radius: 10px;
            //   -moz-border-radius: 10px;
            //   -ms-border-radius: 10px;
            //   -o-border-radius: 10px;
            // }
            // }
          }
        }
      }

      // .containerImg {
      //   img {
      //     width: 398px;
      //   }
      // }
    }
  }
}
@media only screen and (max-width: 620px) {
  .containerTripAdvisor {
    padding-top: 83px;
  }
}
@media only screen and (min-width: 1200px) {
  .containerTripAdvisor {
    height: 380px;
    .containerBackgroundColor {
      background-color: $AnotherSubGray;
      padding-top: 35px;
      > div {
        height: 326px;
      }
      .containerAllGrids {
        height: 326px;
        .containerLeft {
          height: 313px;
          padding-top: 50px;
          .tripLogo {
            height: 84px;
            width: 284px;
            object-fit: contain;
          }
          .slide {
            padding: 20px 0;
            display: flex;
            justify-content: center;
            align-items: center;
            div {
              .cardInfo {
                // padding: 16px;
                // height: 100px;
                width: 500px;
                // height: 115px;
                overflow: hidden;
                .cardMessage {
                  display: flex;
                  justify-content: center;
                  align-items: center;
                }
              }
            }
          }
        }

        .containerImg {
          img {
            transition: all 0.5s ease-in-out;
            width: 398px;
            -webkit-transition: all 0.5s ease-in-out;
            -moz-transition: all 0.5s ease-in-out;
            -ms-transition: all 0.5s ease-in-out;
            -o-transition: all 0.5s ease-in-out;
          }
        }
      }
      &:hover {
        .containerAllGrids {
          .containerImg {
            img {
              margin-left: 50px;
            }
          }
        }
      }
    }
  }
}
@media screen and (min-width: 768px) and (max-width: 1024px) {
  .containerTripAdvisor {
    padding-top: 35px;
  }
}

html[lang="ar"] {
  .containerBackgroundColor {
    &:hover {
      .containerAllGrids {
        .containerImg {
          img {
            margin-left: 0px;
            margin-right: 50px;
          }
        }
      }
    }
  }
}
