@import "../../../../utils/variables/variables.scss";

.ContainerBackground {
  background-image: url("../../../../img/bg-slider-about-us.jpg");
  // background-size: cover;
  background-size: 100%;
  background-color: #f8f8f8;
  background-repeat: no-repeat;
  // background-position: center;
  background-position: bottom;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 50px 0;
  margin-bottom: 35px;
  .packageSlide {
    .slideData {
      align-content: flex-start;
      p {
        margin: 0;
      }
      .subTitle {
        color: $mainDarkGray;
        font-size: 20px;
        line-height: 30px;
        font-family: $D-DIN-REGULAR;
      }
      .Title {
        color: $mainRed;
        font-size: 25px;
        line-height: 30px;
        font-family: "D-DIN-Bold";
        text-transform: uppercase;
        // margin-top: 7px;
      }
      .Description {
        color: $mainDarkGray;
        font-size: 14px;
        line-height: 20px;
        font-family: $DIN-regular;
        margin: 10px 0;
      }
      .btnLearnMore {
        padding: 11px 30px;
        background-color: $mainRed;
        color: $white;
        font-size: 18px;
        line-height: 24px;
        border: none;
        border-radius: 5px;
        -webkit-border-radius: 5px;
        -moz-border-radius: 5px;
        -ms-border-radius: 5px;
        -o-border-radius: 5px;
        margin-top: 36px;
        cursor: pointer;
        margin-bottom: 50px;
      }
    }
    .slideImg {
      text-align: right;
      img {
        margin: 0 auto;
        // max-width: 529px;
        max-height: 358px;
        border-radius: 10px;
        -webkit-border-radius: 10px;
        -moz-border-radius: 10px;
        -ms-border-radius: 10px;
        -o-border-radius: 10px;
      }
    }
  }
}
@media only screen and (min-width: 1280px) {
  .ContainerBackground {
    // height: 517px;
    height: 618px;
    padding: 0;
    margin-top: 100px;
    .packageSlide {
      .slideData {
        .subTitle {
          font-size: 26px;
          line-height: 31px;
        }
        .Title {
          font-size: 45px;
          line-height: 60px;
          // margin-top: 7px;
        }
        .Description {
          font-size: 18px;
          line-height: 25px;
        }
        .btnLearnMore {
          margin-bottom: 0;
        }
      }
    }
  }
}

@media screen and (max-width: 768px) {
  .ContainerBackground {
    padding: 0;
    background-size: auto;
  }
  .packageSlide {
    .slideImg {
      img {
        // margin-bottom: 50px !important;
      }
    }
  }
}
html[lang="ar"] {
  .ContainerBackground {
    direction: rtl;
  }
  .slideData {
    text-align: right;
  }
}
