@import "../../../utils/variables/variables.scss";
.packageCard {
  padding:10px;
  .packageCardContainer {
    position: relative;
    overflow: hidden;
    img {
      position: relative;
      height:400px;
      width:100%;
      border-radius: 5px;
      object-fit: cover;
    }
    .overlay {
      background: rgb(120, 120, 120);
      background: linear-gradient(
        180deg,
        rgba(120, 120, 120, 0) 0%,
        rgba(0, 0, 0, 1) 100%
      );
      border-radius: $Radius;
      width: 100%;
      position: absolute;
      height: 260px;
      bottom: 0;
      left: 50%;
      transform: translateX(-50%);
      text-align: center;
      transition: 0.15s ease-out;
    }
    .detailsContainer {
      position: absolute;
      bottom: 44px;
      left: 50%;
      transform: translateX(-50%);
      text-align: center;
      width: 100%;
      h3 {
        padding-top: 100px;
        text-align: center;
        color: $white;
        font-size: 20px;
        font-family: "Gotham-Bold";
      }
      .book {
        font-size: 16px;
        font-family: "Gotham-Bold";
        text-decoration: none;
        color: #ffffff;
        background-color: #ff173d;
        display: inline-block;
        text-align: center;
        width: 80%;
        height: 42px;
        line-height: 42px;
        border-radius: 5px;
        position: relative;
        z-index: 1;
      }
    }
    button.detailsView {
      position: absolute;
      font-size: 14px;
      font-family: "D-DIN-Bold";
      color: #ffffff;
      text-decoration: underline;
      padding-top: 15px;
      left: 50%;
      bottom: 0;
      transition: all ease 0.3s;
      transform: translateX(-50%);
      display: block;
      visibility: hidden;
      cursor: pointer;
      opacity: 0;
      background-color: transparent;
      border: none;
      outline: none;
    }
  }
  &:hover {
    .packageCardContainer {
      button.detailsView {
        opacity: 1;
        bottom: 17px;
        visibility: visible;
      }
      .overlay {
        height: 100%;
      }
    }
  }
}
//////
.modal {
  // width: 70vw;
  width: 66vw;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  .close {
    // width: 18px;
    width: 32px;
    position: absolute;
    right: 25px;
    top: 30px;
    cursor: pointer;
    z-index: 10;
  }
}
.modal:focus {
  outline: none !important;
}
.containerGridAll {
  // background-color: $subColorBlue;
  background: url("../../../img/bg-gradient-popup.jpg");
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  border-radius: 6px;
  -webkit-border-radius: 6px;
  -moz-border-radius: 6px;
  -ms-border-radius: 6px;
  -o-border-radius: 6px;
  overflow: hidden;
  margin-bottom: 50px;
  .containerImgRight {
    background-repeat: no-repeat;
    background-size: cover;
    min-height: 470px;
    img {
      width: 100%;
      // object-fit: cover;
      object-position: center;
      // max-height: 300px;
    }
  }
  .containerData {
    padding: 50px 24px;
    position: relative;
    &:after {
      content: "";
      background: url("../../../img/mask-snow-popup.png");
      background-repeat: no-repeat;
      background-position: center;
      background-size: cover;
      position: absolute;
      bottom: 0;
      width: 100%;
      height: 39px;
      left: 0;
    }
    .bookBtn a {
      // font-size: 18px;
      // padding: 15px 12px;
      //Coming soon
      padding: 18px 12px;
      font-size: 12px;
      pointer-events: none;
      //Coming soon
      font-family: $DIN-bold;
      text-decoration: none;
      color: $white;
      display: inline-block;
      text-align: center;
      width: 100%;
      border-radius: $Radius;
      border: solid 2px;
      transition: all ease 0.2s;
      margin-top: 25px;
    }
    .bookBtn a:hover {
      background-color: $white;
      color: $subColorBlue;
    }
    p,
    ul {
      margin: 0;
      color: $white;
    }
    .Header {
      margin-bottom: 10px;
      p {
        font-size: 32px;
        line-height: 35px;
        font-family: $D-DIN-BOLD;
        // margin-bottom: 15px;
        // text-transform: uppercase;
      }
      .price {
        font-size: 45px !important;
        line-height: 50px !important;
        text-align: end;
        span {
          font-size: 20px !important;
          font-family: $GOTH-BOOK !important;
          line-height: 24px !important;
        }
      }
    }
    .description {
      a {
        font-size: 14px !important;
        font-family: $DIN-bold;
        text-decoration: none;
        color: $white;
        display: inline-block;
        text-align: center;
        width: 97%;
        padding: 12px;
        border-radius: $Radius;
        border: solid 2px;
        transition: all ease 0.2s;
        margin-top: 5px;
      }
      a:hover {
        background-color: $white;
        color: $subColorBlue;
      }
      p {
        font-size: 20px;
        font-family: $DIN-regular;
        margin-bottom: 15px;
        line-height: 28px;
        // margin-bottom: 15px;
        display: inline-block;
      }
      ul {
        list-style: none;
        // padding: 0 0 0 18px;
        padding: 0;
        margin-bottom: 18px;
        li {
          font-size: 24px;
          line-height: 34px;
          // font-family: $DIN-regular;
          font-family: $GOTH-BOOK;
          // margin-bottom: 8px;
          // &::before {
          //   content: "";
          //   display: inline-block;
          //   border: 1px solid $white;
          //   margin-left: -1em;
          //   margin-right: 10px;
          //   width: 5px;
          // }
        }
      }
      .listHeader {
        margin-bottom: 20px;
      }
    }
    .PackagePerPerson {
      .Header {
        p {
          font-size: 20px;
          line-height: 22px;
          font-family: $DIN-bold;
          margin: 0;
          // text-transform: uppercase;
        }
        span {
          font-family: $DIN-regular;
        }
        a {
          display: inline-block;
          text-align: center;
          background: #fff;
          border-radius: 5px;
          color: #4a6ee8;
          text-decoration: none;
          font-family: "D-DIN-Bold";
          // padding: 18px 50px;
          height: 52px;
          // width: 240px;
          width: 100%;
          font-size: 18px;
          // margin-top: 8vh;
          margin-top: 27px;
          border: none;
          line-height: 52px;
        }
      }
      .description {
        text-align: center;
        p {
          font-size: 38px;
          font-family: $FBT-heavy;
          line-height: 60px;
          color: $white;
          span {
            font-size: 18px;
            font-family: $FBT-medium;
            line-height: 24px;
          }
        }
        .OR {
          font-size: 28px;
          font-family: $FBT-heavy;
          line-height: 42px;
        }
        > div {
          > div {
            text-align: initial;
          }
        }
      }
    }
    .header2 {
      text-align: center;
      margin-bottom: 22px;
      h2 {
        font-size: 28px;
        line-height: 37px;
        font-family: "D-DIN-Bold";
        margin: 0 0 5px 0;
        text-transform: uppercase;
        color: $white;
      }
      p {
        font-size: 18px;
        line-height: 24px;
        font-family: $DIN-regular;
        margin: 0;
        text-align: left;
        white-space: break-spaces;
      }
    }
  }
}
.containerGridAll:last-child {
  margin-bottom: 0;
}
////////
@media screen and (max-width: 768px) {
  //////
  .containerGridAll {
    overflow: hidden;
    margin-bottom: 50px;
    .containerImgRight {
      img {
        max-height: 300px;
      }
    }
    .containerData {
      padding: 22px 12px;
      .bookBtn a {
        padding: 8px 5px;
        font-size: 10px;
        margin-top: 5px;
      }
      .Header {
        margin-bottom: 10px;
        p {
          font-size: 18px;
          line-height: 22px;
          margin-bottom: 10px;
        }
      }
      .description {
        a {
          font-size: 12px !important;
          width: 97%;
          padding: 10px;
          margin-top: 5px;
        }

        p {
          font-size: 18px;
          margin-bottom: 15px;
          line-height: 22px;
        }
        ul {
          list-style: none;
          padding: 0 0 0 18px;
          // margin-bottom: 18px;
          li {
            font-size: 18px;
            line-height: 20px;
            font-family: $DIN-regular;
            // margin-bottom: 8px;
            &::before {
              content: "";
              display: inline-block;
              border: 1px solid $white;
              margin-left: -1em;
              margin-right: 10px;
              width: 5px;
            }
          }
        }
        .listHeader {
          margin-bottom: 20px;
        }
      }
      .PackagePerPerson {
        .Header {
          p {
            font-size: 20px;
            line-height: 22px;
            margin: 0;
            // text-transform: uppercase;
          }
          .price {
            text-align: inherit;
          }
          a {
            // padding: 11px 45px;
            font-size: 18px;
            margin-top: 2vh;
          }
        }
        .description {
          p {
            font-size: 38px;
            line-height: 60px;
            span {
              font-size: 18px;
              line-height: 24px;
            }
          }
          .OR {
            font-size: 28px;
            line-height: 42px;
          }
        }
      }
      .header2 {
        margin-bottom: 22px;
        h2 {
          font-size: 28px;
          line-height: 37px;
          margin: 0 0 5px 0;
        }
        p {
          font-size: 18px;
          line-height: 24px;
          margin: 0;
        }
      }
    }
  }
  ////////
}
html[lang="ar"] {
  .containerData {
    direction: rtl;
  }
  .Header {
    .title {
      direction: ltr;
      text-align: right;
      margin-top: 15px;
    }
  }
}

@media screen and (max-width: 767px) {
  .containerGridAll {
    .containerImgRight {
      min-height: 250px;
    }
  }
}
