.swiper-button-next,
.swiper-button-prev {
    color: #E8E8E8;
}
.swiper-button-next:after,
.swiper-button-prev:after {
    font-size: 20px;
    font-weight: bold;
}
@media only screen and (max-width: 768px) {
    .swiper-button-next,
    .swiper-button-prev {
        display: none;
    }
}
