@import "../../../../utils/variables/variables.scss";

.mainContainer {
  background-image: url(../../../../img/levelsbg.png);
  margin: 0px auto;
  background-repeat: no-repeat;
  background-size: 100% 100%;
  height: auto !important;
  padding: 5px;
  margin-bottom: 100px;
  /* The actual timeline (the vertical ruler) */
  .timeline {
    // padding-top:50px;
    position: relative;
    max-width: 1200px;
    height: 90%;
    flex-direction: column;
    display: flex;
    justify-content: center;
  }

  /* The actual timeline (the vertical ruler) */
  .timeline::before {
    border-radius: 1px 20px 20px 1px;
    border-top: 1px solid white;
    border-bottom: 1px solid white;
    content: "";
    position: absolute;
    width: 25px;
    // background-color: rgb(255, 255, 255);
    border-right: 1px solid white;
    top: 9px;
    bottom: 50px;
    left: 0;
    margin-left: -29px;
  }

  .secondtimeline {
    position: relative;
    max-width: 1200px;
    margin: 0 auto;
    height: 95%;
  }

  .secondtimeline::before {
    content: "";
    position: absolute;
    width: 1px;
    background-color: rgb(255, 255, 255);
    top: 10px;
    bottom: 10px;
    left: 0;
    margin-left: -30px;
  }

  .title__container {
    // padding: 10px 40px;
    position: relative;
    background-color: inherit;
    width: 100%;
    margin-bottom: 50px;
    margin-top: 50px;
  }

  /* The circles on the timeline */
  .title__container::after {
    content: "";
    position: absolute;
    width: 25px;
    height: 25px;
    right: -17px;
    background-image: url("../../../../img/snowflake.png");
    top: 6px;
    z-index: 1;
  }
  .footer__container::after {
    content: "";
    position: absolute;
    width: 25px;
    height: 25px;
    right: -17px;
    background-image: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABkAAAAZCAMAAADzN3VRAAAAAXNSR0IB2cksfwAAAAlwSFlzAAALEwAACxMBAJqcGAAAAH5QTFRFAAAA////////////////////////////zdTumqndZ37MQV6/8/T7dInQNVS72d/yTmnD////dIrQp7XhQl+/W3TIgZTV5ur3////zdXu////mqrdaH/M////zNTus7/l8vT7prThwMrqzNTt////gZTU8vT62N7ymancZn7LUWEsEQAAACp0Uk5TABBfr8//f+////////////+A////////YP+w///Q////////8P//////agejgAAAAQ1JREFUeJyVUtuWgjAQo0rkUqjSWotclwVR//8HnXLKRV727LzNpMmkaT3vr2KHow/4xwPbzU8BXAWnLcboeBjFnMdRSMQVYgGSVLhKEwRsZZwvYqnLeWFlSLgdSSXlBCXIZi2Sump1g9FKX0nQ6eUI6aA2d6GkuBtNTYjcIgUiahTNyopwRU2EwiI1YtqhidW0xLjRrhi+RQDar37SuG27JurQCcGByYJFhCnL37ZVlTTSIt63mlKkpie1euOgMboiB83GQY7euZY712yYb2rcTR8YmEtnnGKT0qUzunQ8VuO5TfSJegl7wLi8wmOctRwL/evN+fvVY2VMUDbMrz1k+5+QF0Ssi3w3/199AM1FGIkQWl2UAAAAAElFTkSuQmCC);
    bottom: 5px;
    z-index: 1;
  }
  /* Place the container to the right */
  .title {
    left: 0;
    h2 {
      font-family: $D-DIN-REGULAR;
      font-size: 32px;
    }
  }
  #title__text {
    font-size: 62px;
    font-weight: 100;
  }
  #number {
    color: #ff173d;
  }
  #bold {
    font-weight: 600;
  }

  /* Fix the circle for containers on the right side */
  .title::after {
    left: -42px;
  }

  /* Container around content */
  // .container {
  //   padding: 10px 40px;
  //   position: relative;
  //   background-color: inherit;
  //   width: 100%;
  //   margin-bottom: 50px;
  //   margin-top: 50px;
  // }

  /* The circles on the timeline */
  .right::after {
    content: "";
    position: absolute;
    width: 25px;
    height: 25px;
    left: -17px;
    background-image: url("../../../../img/snowflake.png");
    top: 100px;
    z-index: 1;
  }

  /* Place the container to the right */
  .right {
    left: 0;
    position: relative;
  }

  /* Fix the circle for containers on the right side */
  .right::after {
    left: -16px;
  }

  /* The actual content */
  .content {
    color: white;
    padding: 100px 0px 0px 30px;
    background-color: transparent;
    position: relative;
    border-radius: 6px;

    &__button {
      width: 35%;
      height: 40px;
      border: none;
      background-color: #ffffff;
      border-radius: 10px;
      color: #3452b8;
      text-transform: uppercase;
      font-weight: 600;
      margin-right: 10px;
    }
    &__button__secondary {
      width: 35%;
      height: 40px;
      border: 1px solid white;
      background-color: transparent;
      border-radius: 10px;
      color: white;
      text-transform: uppercase;
      font-weight: 600;
    }
  }

  h2 {
    text-transform: uppercase;
    font-size: 23px;
    font-family: $DIN-bold;
  }
  p {
    font-size: 20px;
    font-family: $GOTH-BOOK;
    line-height: 29px;
  }
}
@media (max-width: 767px) {
  .container2 {
    display: none !important;
  }
  .container3 {
    display: block !important;
  }
}
@media (max-width: 1024px) {
  .title::after {
    left: 0 !important;
  }

  .timeline::before {
    left: 42px !important;
  }
  .secondtimeline::before {
    left: 42px !important;
  }
  .container {
    width: 80%;
  }
  .container::after {
    top: 36px !important;
  }
  .right::after {
    left: 26px !important;
  }
  .content {
    &__button {
      width: 100% !important;
    }
    &__button__secondary {
      width: 100% !important;
      margin-top: 5px;
    }
  }
}

html[lang="ar"] {
  .content {
    text-align: right;
  }
}
