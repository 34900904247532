@import "../../../../utils/variables/variables.scss";
.corporateBenefitsContainer {
  padding-top: 80px;
  h3 {
    color: $mainRed;
    font-size: 28px;
    font-family: "Gotham-Bold";
    text-transform: uppercase;
    margin: 0;
  }
  p {
    font-family: $DIN-regular;
    font-size: 18px;
    line-height: 24px;
    color: $mainDarkGray;
    white-space: break-spaces;
  }
}
