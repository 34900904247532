@import "../../utils/variables/variables.scss";
.home {
  position: relative;
  .search {
    .searchFieldFlex {
      display: flex;
      justify-content: center;
      align-items: center;
    }
    .searchField {
      // padding: 0 10px 0 35px;
      position: relative;
      border: solid 2px $subColorBlue;
      border-top-left-radius: 5px;
      border-bottom-left-radius: 5px;
      padding: 15px 10px 15px 35px;
      background: $white;
      & > img {
        position: absolute;
        left: 8px;
        top: 20px;
      }
      .containerDatePicker {
        margin: 0;
      }
      div {
        position: relative;
        width: 100%;
        // &::after {
        //   content: "";
        //   display: block;
        //   position: absolute;
        //   right: 15px;
        //   top: 40%;
        //   cursor: pointer;
        //   width: 13px;
        //   height: 8px;
        //   background-repeat: no-repeat;
        //   background-size: cover;
        //   background: url(../../img/blueArrow.png);
        // }
        input::placeholder {
          color: $mainDarkGray;
          font-family: "D-DIN";
        }
        input {
          width: 100%;
        }
        ul {
          // transform: translate(-37px, 18px);
          transform: translate(-37px, -110%);
          width: calc(100% + 49px);
          border: solid 2px #4a6ee8;
          border-top: none;
          border-radius: 0px 0px $Radius $Radius;
          display: flex;
          justify-content: flex-start;
          flex-direction: column;
          li {
            color: #afafaf;
            font-size: 16px;
            font-family: "D-DIN";
            // margin: 18px 0 !important;
          }
        }
      }
    }
    .searchField:after {
      content: "";
      display: block;
      position: absolute;
      height: 45px;
      border: solid 1px #afafaf1f;
      top: -1px;
      right: 0;
    }
    .text {
      text-transform: uppercase;
      color: $white;
      h4 {
        font-family: $D-DIN-REGULAR;
        // font-size: 36px;
        font-size: 20px;
        line-height: 20px;
        margin: 0;
      }
      h2 {
        font-family: $D-DIN-BOLD;
        // font-size: 45px;
        font-size: 36px;
        line-height: 36px;
        margin: 0;
      }
    }
    .bar {
      // border: solid 2px $subColorBlue;
      border-radius: 5px;
      -webkit-border-radius: 5px;
      -moz-border-radius: 5px;
      -ms-border-radius: 5px;
      -o-border-radius: 5px;
      button.SearchBtn {
        width: 100%;
        font-family: $D-DIN-BOLD;
        background: $subColorBlue;
        display: inline-block;
        border: none;
        font-size: 20px;
        line-height: 0px;
        // margin: -1px;
        cursor: pointer;
        border-top-right-radius: 5px;
        border-bottom-right-radius: 5px;
        color: #ffffff;
        padding: 31px 0;
        // a {
        // padding: 31px 0;
        //   padding: 26.9px 0;
        //   text-decoration: none;
        //   color: #ffffff;
        //   display: inline-block;
        //   width: 96%;
        //   height: 100%;
        // }
      }
      .select {
        background-color: $white;
      }
      .searchInput {
        min-width: 100%;
        & * {
          font-family: $DIN-regular;
          color: #afafaf;
        }
        & > div {
          z-index: 9;
        }
        svg {
          path {
            color: $mainRed;
          }
        }
        &::before {
          display: none;
        }
        img {
          position: absolute;
          right: 0;
        }
      }
    }
  }
  .offers {
    .cardWrapperLink {
      text-decoration: none;
    }
    .reverse {
      flex-direction: row-reverse;
    }
    .readMore {
      text-transform: uppercase;
      color: $white;
      font-size: 20px;
      font-family: $D-DIN-BOLD;
      line-height: 62px;
      text-decoration: none;
      background-color: $mainRed;
      width: 247px;
      height: 62px;
      border-radius: 12px;
      text-align: center;
      cursor: pointer;
      margin: 25px auto 0;
    }
    .offersWrap {
      & > div {
        padding: 12px 6px;
      }
      .slide:hover .more {
        transform: translateY(0);
      }
      .slide img:hover {
        transform: scale(1.05);
        // transform-origin: top;
      }
      .cardWrapper {
        padding: 0 5px;
      }

      .cardImgWrap {
        overflow: hidden;
        border-radius: $Radius;
        max-width: 280px;
        img {
          border-radius: inherit;
          transition: ease all 0.3s;
        }
        .sking {
          // height: 100%;
          object-fit: cover;
        }
      }
    }
    h6 {
      color: $mainDarkGray;
      font-size: 24px;
      line-height: 34px;
      font-family: $D-DIN-REGULAR;
      margin: 100px 0 0px;
    }
    h2 {
      color: $mainRed;
      font-size: 42px;
      line-height: 45px;
      font-family: "D-DIN-Bold";
      margin: 0px 0 30px 0;
    }
    .title {
      color: $mainRed;
      font-family: $D-DIN-BOLD;
      font-size: 32px;
      line-height: 30px;
      padding: 37px 0 22px 0;
      margin: 0;
      transition: ease all 0.3s;
      text-transform: uppercase;
    }
    .excerpt {
      font-size: 18px;
      color: $mainDarkGray;
      font-family: $GOTH-BOOK;
      line-height: 26px;
    }
    .book {
      background-color: $subColorBlue;
      font-size: 20px;
      font-family: $DIN-bold;
      color: $white;
      width: 99%;
      display: inline-block;
      text-align: center;
      padding: 14px 0;
      border-radius: $Radius;
      text-decoration: none;
      transition: all ease 0.3s;
      margin-top: 20px;
    }
    .more {
      text-align: left;
      font-family: $D-DIN-BOLD;
      font-size: 16px;
      line-height: 17px;
      color: $mainRed;
      margin-top: 0;
      // display: inline-block;
      width: 100%;
      transition: all ease 0.3s;
      display: none;
      background: none;
      outline: none;
      border: none;
      cursor: pointer;
      text-decoration: underline;
      padding: 0;
    }
    .book:hover {
      text-decoration: none;
    }
  }
  .save {
    background-image: url("../../img/save.jpg");
    background-size: cover;
    min-height: 356px;
    background-position: 50%, 50%;
    padding: 100px 0;
    button {
      width: 262px !important;
      height: 62px !important;
      a {
        // padding: 15px 0px !important;
        padding: 0 !important;
        line-height: 56px !important;
        text-transform: uppercase;
      }
    }
    .banner {
      background-color: #fcfcfc;
      .title {
        font-size: 45px;
        line-height: 60px;
        margin: 0;
        color: $subColorBlue;
        font-family: "D-DIN-Bold";
        text-transform: uppercase;
        text-align: center;
        padding-top: 40px;
      }
      .itemWrap {
        padding-top: 50px;
        padding-bottom: 50px;
        // padding: 50px;
        border-radius: $Radius;
        .item {
          text-align: center;
          align-self: baseline;
          h2 {
            line-height: 26px;
            margin: 25px 0 0;
            font-family: $DIN-bold;
            font-size: 20px;
            color: $subColorBlue;
          }
        }
      }
    }
    .offersContainer {
      button {
        a {
          text-transform: uppercase;
        }
      }
    }
    .text {
      .more {
        color: $white;
        text-decoration: none;
        font-family: $DIN-bold;
        font-size: 20px;
        line-height: 22px;
        display: inline-block;
        transform: translateX(-10%);
        transition: ease all 0.2s;
        padding: 15px 80px;
        border-radius: $Radius;
      }
      h6 {
        font-size: 24px;
        margin: 0;
        font-family: $D-DIN-REGULAR;
        color: $white;
        line-height: 55px;
      }
      h5 {
        font-size: 42px;
        line-height: 55px;
        margin: 0;
        font-family: $D-DIN-BOLD;
        color: $white;
      }
      h4 {
        font-size: 45px;
        line-height: 60px;
        margin: 0;
        font-family: $FBT-heavy;
        color: $white;
      }
    }
  }
  .packages {
    .title {
      font-size: 24px;
      line-height: 45px;
      margin: 0 0 0 68px;
      color: $mainDarkGray;
      font-family: $D-DIN-REGULAR;
    }
    .subTitle {
      font-size: 42px;
      line-height: 45px;
      color: $mainRed;
      font-family: "D-DIN-Bold";
      margin: 0 0 60px 68px;
    }
    .packageSlide:first-child {
      margin-left: 140px;
      margin-right: 0;
    }
    .packageSlide {
      .cardImgWrap {
        img {
          position: relative;
          width: 100%;
          // border-radius: 12px;
          object-fit: cover;
        }
        h4 {
          font-size: 18px;
          line-height: 24px;
          font-family: $FBT-medium;
          color: $white;
          margin: 0;
          opacity: 1;
          visibility: visible;
          transition: all ease 0.3s;
        }
        h2 {
          font-size: 28px;
          line-height: 30px;
          font-family: $D-DIN-BOLD;
          color: $mainRed;
          margin: 0;
        }
        p {
          font-size: 16px;
          line-height: 22px;
          font-family: $GOTH-BOOK;
          color: $white;
          margin: 0;
        }
        .book {
          // font-size: 18px;
          //Coming soon
          font-size: 18px;
          // pointer-events: none;
          //Coming soon
          font-family: $D-DIN-BOLD;
          text-transform: uppercase;
          text-decoration: none;
          color: $white;
          background-color: $mainRed;
          display: inline-block;
          text-align: center;
          width: 100%;
          padding: 12px;
          border-radius: $Radius;
          position: relative;
          z-index: 1;
        }
        .view {
          display: inline-block;
          width: 100%;
          text-align: center;
          // margin-top: 15px;
          color: $white;
          font-size: 14px;
          font-family: $D-DIN-REGULAR;
          position: absolute;
          bottom: 0px;
          left: 20px;
          line-height: 16px;
          transition: all ease 0.3s;
          text-decoration: none;
          position: relative;
          padding-bottom: 6px;
          &:after {
            content: "";
            position: absolute;
            bottom: 0px;
            width: 50%;
            left: 50%;
            transform: translateX(-50%);
            height: 1px;
            background-color: $white;
          }
        }
        .cardImgContainer {
          position: absolute;
          bottom: 0;
          left: 0;
          padding: 0 25px;
          margin-bottom: 5px;
          & > div:nth-child(2) {
            position: relative;
            z-index: 1;
            // bottom: 15px;
            bottom: 0;
          }
        }
      }
    }
    .hotOffers {
      position: absolute;
      z-index: 1;
      width: 60px;
      right: 15px;
      top: 15px;
    }
  }
  .video {
    margin: 95px 0 30px 0;
    padding: 60px 140px;
    // background-color: $AnotherSubGray;
    h4 {
      font-family: $D-DIN-REGULAR;
      font-size: 24px;
      color: $mainDarkGray;
      line-height: 45px;
      margin: 0;
      text-transform: uppercase;
    }
    h2 {
      font-family: "D-DIN-Bold";
      font-size: 42px;
      color: $subColorBlue;
      line-height: 45px;
      margin: 0 0 15px;
    }
    p {
      font-family: $GOTH-BOOK;
      font-size: 18px;
      color: $mainDarkGray;
      line-height: 25px;
      margin: 0;
    }
    .play {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      cursor: pointer;
    }
    .placeHolder {
      transition: ease all 0.3s;
      object-fit: cover;
      border-radius: $Radius;
    }
    &:hover .placeHolder {
      transform: scale(1.01);
      transform-origin: center;
    }
    iframe {
      border-radius: $Radius;
    }
  }
}
@keyframes flash {
  0% {
    background: rgba($color: $white, $alpha: 0.3);
    display: block;
  }
  100% {
    background: transparent;
    display: none;
  }
}
@media (min-width: 1200px) {
  html[lang="ar"] {
    .home {
      .search {
        right: 70px;
        left: auto;
      }
    }
  }
  .home {
    .search {
      position: absolute;
      // top: 50%;
      // left: 50%;
      // transform: translate(-50%, -50%);
      bottom: 44px;
      left: 70px;
      z-index: 99;
    }
    // .textContainer {
    // justify-content: center;
    // }
    .offers {
      .slide {
        height: 100%;
        > div {
          height: 100%;
          height: 100%;
          display: flex;
          flex-direction: column;
          justify-content: space-between;
        }
      }
      .more {
        opacity: 0;
        visibility: hidden;
        transform: translateY(50px);
      }
    }
    .save {
      margin: 100px 0 90px;
      padding: 100px 0;
      .banner {
        transform: translateY(-50%);
      }
      .text {
        // transform: translateY(-50%);
        .more {
          visibility: hidden;
          opacity: 0;
          background-color: $white;
        }
      }
    }
    .save:hover .text .more {
      transform: translateX(0);
      background-color: $subColorBlue;
      visibility: visible;
      opacity: 1;
    }
    .packages {
      // max-width: 100vw;
      // padding-right: 0;

      .packageSlide:hover .view {
        opacity: 1;
        visibility: visible;
        left: 0;
        // margin-bottom: 20px;
      }
      .packageSlide:hover {
        .book {
          margin-bottom: 15px;
        }
      }
      .packageSlide:hover .cardImgContainer > div:nth-child(2) {
        margin-bottom: 20px;
      }
      // .packageSlide:hover .cardImgWrap:after {
      //   animation: flash 0.3s;
      // }
      .packageSlide:hover .cardImgContainer h4 {
        opacity: 0;
        visibility: hidden;
      }

      .cardImgWrap {
        position: relative;
        // img{
        //     min-height: 380px;
        // }
        &:after {
          content: "";
          width: 100%;
          height: 100%;
          position: absolute;
          top: 0;
          display: block;
          left: 0;
        }
        .view {
          opacity: 0;
          visibility: hidden;
          transition: ease all 0.3s;
        }
      }
    }
    .packageSlide {
      .cardImgWrap {
        .cardImgContainer {
          position: absolute;
          bottom: 29px !important;
          left: 0;
          padding: 0 25px;
          margin-bottom: 10%;
          & > div:first-child {
            padding-right: 20px;
            // padding-left: 20px;
            // margin-bottom: 10px;
            // min-height: 220px;
          }
        }
      }
    }
  }
  .slide .title:hover {
    // transform: scale(1.05);
    margin-left: 5px;
  }
  .offers .slide .book:hover {
    // transform: scale(1.05);
    background-color: $mainRed;
  }
  .slide:hover {
    .more {
      opacity: 1;
      visibility: visible;
    }
  }
  .video {
    .placeHolder {
      min-height: 376px;
    }
  }
}
@media (max-width: 1199px) {
  .home {
    .search {
      position: absolute;
      bottom: 44px;
      left: 50%;
      transform: translate(-50%, 0);
      z-index: 99;
      // padding: 0 15px;
      .textContainer {
        // transform: translateY(-50px);
        .text {
          h2 {
            font-size: 40px;
            line-height: 45px;
            margin: 10px 0 0;
          }
          h4 {
            font-size: 25px;
          }
        }
      }
      .bar {
        button.SearchBtn {
          // padding: 28px;
          font-size: 16px;
          // margin: 22px !important;
        }
      }
    }
    .offers {
      // .more {
      //   transform: translateY(0);
      //   margin-bottom: 15px;
      //   text-align: right;
      // }
      h2 {
        text-align: center;
        margin: 10px 0 5px;
        font-size: 26px;
        line-height: 45px;
        margin-bottom: 25px;
      }
      h6 {
        text-align: center;
        font-size: 20px;
      }
      .cardWrapper {
        .title {
          font-size: 26px;
          // margin: 22px 0 0;
        }
      }
      .book {
        padding: 10px 0 !important;
      }
    }
    .save {
      margin-top: 50px;
      margin-bottom: 50px;

      // .banner {
      //   .title {
      // font-size: 35px !important;
      // line-height: 45px !important;
      // }
      // }
      // .itemWrap {
      //   .item {
      //     h2 {
      // font-size: 20px;
      // margin: 5px 0 0;
      //     }
      //   }
      // }
      // .text {
      // margin-top: 50px;
      // padding-bottom: 50px;
      // .more {
      // background-color: $subColorBlue;
      // transform: translateX(0%);
      // }
      // h4 {
      // font-size: 30px;
      // line-height: 50px;
      // margin: 0 0 25px;
      // }
      // h5 {
      // font-size: 30px;
      // line-height: 34px;
      // }
      // }
    }
    .packages {
      // .title {
      // margin-top: 50px;
      // font-size: 20px;
      // }
      // .subTitle {
      // font-size: 26px;
      // margin: 0 0 15px;
      // line-height: 35px;
      // }
      .packageSlide {
        .cardImgContainer {
          bottom: 25px !important;
          // position: relative !important;
          // padding: 0 !important;
          // h4 {
          //   color: $mainDarkGray;
          //   margin-top: 20px;
          // }
          // h2 {
          //   font-size: 22px;
          //   margin-top: 10px;
          // }
          // p {
          //   color: $mainDarkGray;
          //   margin: 15px 0 40px 0;
          // }
        }
      }
    }
    // .video {
    //   h2 {
    //     font-size: 27px;
    //   }
    //   margin-top: 20px !important;
    // }
  }
}
@media (max-width: 767px) {
  html[lang="ar"] {
    .searchField {
      > img {
        right: 10px !important;
      }
    }
  }
  .searchField {
    border-top-left-radius: 8px !important;
    border-bottom-left-radius: 0px !important;
    border-top-right-radius: 8px;
    // div {
    // ul {
    // transform: translate(-37px, 16px) !important;
    // }
    // }
    > img {
      top: 17px !important;
    }
  }
  .offers {
    .readMore {
      display: none;
    }
    h6 {
      margin: 56px 0 0 !important;
      font-size: 16px !important;
      text-align: left !important;
    }
    h2 {
      font-size: 28px !important;
      text-align: left !important;
      line-height: 35px !important;
      margin: 0 !important;
    }
    .cardWrapper {
      .title {
        font-size: 24px !important;
        // margin: 22px 0 !important;
        padding: 29px 0 19px 0;
        margin: 0;
        line-height: 12px !important;
      }
    }
    .offersWrap {
      .cardImgWrap {
        max-width: 100% !important;
        img {
          width: 100%;
        }
      }
    }
    .excerpt {
      font-size: 14px !important;
      line-height: 20px !important;
    }
    .more {
      display: inline-block !important;
    }
  }
  .search {
    bottom: 66px !important;
    .bar {
      button.SearchBtn {
        height: 52px !important;
        border-top-right-radius: 0px !important;
        border-bottom-right-radius: 8px !important;
        border-bottom-left-radius: 8px;
      }
      .searchInput {
        min-height: 40px;
        & > div {
          z-index: 9;
        }
        svg {
          transform: translateX(5px);
        }
      }
    }
    .textContainer {
      transform: translate(0) !important;
      margin-bottom: 12px;
      .text {
        h2 {
          font-size: 28px !important;
          line-height: 35px !important;
          margin: 0 !important;
        }
        h4 {
          font-size: 20px !important;
          line-height: 40px !important;
        }
      }
    }
  }
  .save {
    // background-image: url("../../img/saveMobile.png") !important;
    // background-size: contain !important;
    padding: 56px 0 !important;
    min-height: auto !important;
    margin-top: 50px;
    .item {
      margin-top: 25px;
      img {
        height: 60px;
      }
      .goggles {
        height: 40px;
      }
    }
    .text {
      h6 {
        font-size: 16px !important;
        line-height: 39px !important;
        // margin-top: 55px !important;
      }
      h5 {
        font-size: 28px !important;
        line-height: 39px !important;
      }
    }
    button {
      border-radius: 12px !important;
      line-height: 17px !important;
      margin-top: 30px !important;
      width: 183px !important;
      height: 52px !important;
      a {
        // padding: 15px 0px !important;
        font-size: 16px !important;
      }
    }
  }
  .saveUp {
    opacity: 1 !important;
    visibility: visible !important;
  }
  .packages {
    .packageSlide:first-child {
      margin-left: 0px !important;
    }
    .packageSlide {
      .cardImgWrap {
        h2 {
          font-size: 20px !important;
          line-height: 22px !important;
        }
        p {
          font-size: 12px !important;
          line-height: 14px !important;
          margin: 16px 0 !important;
        }
        .book {
          font-size: 14px !important;
          line-height: 16px !important;
          border-radius: 12px !important;
          // padding: 10px 44px !important;
          width: 50% !important;
        }
        .view {
          width: 50% !important;
        }
        .cardImgContainer {
          bottom: 30px !important;
        }
      }
    }
    .title {
      font-size: 16px !important;
      line-height: 35px !important;
      margin: 56px 0 0 0px !important;
    }
    .subTitle {
      font-size: 28px !important;
      line-height: 35px !important;
      margin: 0 !important;
    }
  }
  .home {
    .video {
      padding: 52px 0px !important;
      margin-top: 0;
      h4 {
        font-size: 16px !important;
        line-height: 35px !important;
      }
      h2 {
        font-size: 28px !important;
        line-height: 35px !important;
      }
      p {
        font-size: 14px !important;
        line-height: 20px !important;
      }
    }
  }
  html[lang="ar"] {
    .search {
      .textContainer {
        display: flex;
        justify-content: flex-end;
        text-align: right;
      }
    }
  }
}
@media (min-width: 768px) {
  html[lang="ar"] {
    .searchField {
      > img {
        right: auto !important;
        top: 25px !important;
      }
    }
    .home {
      .search {
        .textContainer {
          .text {
            text-align: right;
          }
        }
      }
    }
  }
  .home {
    .search {
      .textContainer {
        // justify-content: center;
        margin-bottom: 25px;
        .text {
          // text-align: center;
          text-align: left;
        }
      }
    }
    .searchField {
      > img {
        left: 10px !important;
        top: 17px !important;
      }
      // padding: 20px 10px 20px 35px !important;
      input {
        // font-size: 16px !important;
        font-size: 18px;
        font-family: $GOTH-BOOK;
        color: #afafaf;
        padding-left: 10px;
      }
      input:placeholder {
        color: #afafaf;
      }
    }
  }
}
html[lang="ar"] {
  .searchInput > div {
    font-family: "Cairo" !important;
  }
  .searchField {
    .searchInput {
      text-align: right;
      img {
        right: auto;
        left: 0 !important;
      }
    }
    div {
      input {
        text-align: right;
        padding-right: 30px;
      }
      input::placeholder {
        font-family: "Cairo" !important;
      }
      ul {
        li {
          text-align: right;
          padding-right: 15px;
        }
      }
    }
  }
  .searchField div::after {
    right: 10px !important;
  }
  .offers {
    text-align: right;
    .excerpt {
      direction: rtl;
    }
  }
  .save {
    .text {
      h6,
      h5,
      h4 {
        text-align: right;
      }
    }
  }
  .packages {
    .packageSlide:first-child {
      margin-right: 140px;
      margin-left: 0;
    }
    .cardImgContainer {
      text-align: right;
      > div:first-child {
        direction: rtl;
      }
    }
    > h6,
    h2 {
      text-align: right;
    }
    h6 {
      text-align: right;
    }
  }
  .video {
    text-align: right;
    direction: rtl;
  }
  .slide .title:hover {
    // transform: scale(1.05);
    margin-left: 0 !important;
    margin-right: 5px;
  }
  .search {
    .bar {
      .SearchBtn {
        line-height: 10px !important;
        border-top-left-radius: 5px;
        border-bottom-left-radius: 5px;
      }
    }
  }
}
// @media (max-width: 769px) {
//   .save {
//     background-image: url("../../img/saveMobile.png") !important;
//     background-size: contain !important;
//   }
// }
