@import "../../../../utils/variables/variables.scss";
.container{
    background-image: url(../../../../img/personalitytest.png);
    padding: 56px 0 !important;
    background-size: cover;
    height: 100%;
    width: auto;

    .text {
        h6 {
          color: white;
          font-size: 24px;
          line-height: 34px;
          font-family: $D-DIN-REGULAR;
          // margin: 100px 0 0px;
        }
        h5 {
            text-transform: uppercase;
            font-size: 42px;
            line-height: 55px;
            margin: 0;
            font-family: $D-DIN-BOLD;
            color: $white;
        }
        
      }
      button {
        padding: 15px 0 !important;
        color: #4a6ee8 !important;
        text-decoration: none !important;
        font-family: "D-DIN-Bold" !important;
        font-size: 20px !important;
        line-height: 22px !important;
        display: inline-block;
        border-radius: 12px !important;
        line-height: 17px !important;
        margin-top: 30px !important;
        width: 183px !important;
        height: 52px !important;
        border:none;
        a {
            text-transform: uppercase;
          font-size: 16px !important;
          text-decoration: none;
        }
      }
      p{
        font-size: 16px;
        line-height: 22px;
        font-family: $GOTH-BOOK;
        color: $white;
        margin: 0;
        width:50%
      }
      @media (max-width: 1024px){
        p{
          width:80%;
        }
      }
        @media (max-width: 767px){
          p{
            width:100%;
            font-size: 14px;
          }
      }
}