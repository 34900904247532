@import "../../../utils/variables/variables.scss";
.YourExperience {
  overflow-y: hidden;
  padding-top: 60px;
  padding-bottom: 50px;
  .TitelYourExperience {
    p {
      color: $mainDarkGray;
      font-size: 28px;
      font-family: $DIN-regular;
      span {
        font-family: $DIN-bold;
        color: $mainRed;
      }
    }
  }
  .containersocialIcon {
    .socialIcon {
      text-align: center;
      .globe {
        svg {
          fill: $mainRed !important;
        }
        & * {
          fill: $mainRed !important;
        }
      }
      .facebook {
        svg {
          fill: $subGray;
          font-size: 25px;
        }
        & * {
          fill: $subGray;
        }
      }
      .facebook:hover {
        svg {
          fill: $mainRed;
          font-size: 25px;
        }
        & * {
          fill: $mainRed;
        }
      }
      .Line {
        border-right: 2px solid $mainDarkGray;
        svg {
          &:hover {
            fill: $mainRed;
          }
        }
      }
      .label {
        color: $mainDarkGray;
        font-size: 18px;
        line-height: 24px;
        font-family: $DIN-regular;
        text-transform: capitalize;
        margin-top: 10px;
      }
    }
  }
}
.slide {
  // > div {
  //     height: 450px;
  // }
  .cardImgWrap {
    position: relative;
    transition: all 0.9s ease-in-out;
    -webkit-transition: all 0.9s ease-in-out;
    -moz-transition: all 0.9s ease-in-out;
    -ms-transition: all 0.9s ease-in-out;
    -o-transition: all 0.9s ease-in-out;
    height: 100%;
    > img {
      height: 280px;
      width: 100%;
      object-fit: cover;
    }
    svg {
      font-size: 40px;
      opacity: 0;
    }

    &:hover {
      .containerOverlaye {
        position: absolute;
        background-color: rgba(0, 0, 0, 0.6);
        width: 100%;
        height: 280px;
        left: 0;
        top: 0;
        display: flex;
        align-items: center;
        justify-content: center;
        svg {
          fill: $white;
          width: 50px;
          & * {
            fill: $white;
          }
          opacity: 1;
          transition: all ease 0.3s;
        }
      }
    }
  }
}
@media only screen and (max-width: 768px) {
  .YourExperience {
    .TitelYourExperience {
      p {
        font-size: 20px;
        text-align: center !important;
      }
    }
    .containersocialIcon {
      justify-content: center !important;
      .socialIcon {
        .label {
          font-size: 15px;
        }
      }
    }
  }

  .slide {
    .cardImgWrap {
      // position: absolute;
      > img {
        // width: 100%;
        height: 320px;
      }
      .containerOverlaye {
        position: absolute;
        background-color: rgba(0, 0, 0, 0);
        width: 100%;
        height: 320px !important;
        left: 0;
        top: 0;
        display: flex;
        align-items: center;
        justify-content: center;
      }
    }
  }
}
html[lang="ar"] {
  .fbSlides {
    direction: rtl;
  }
  .Line {
    border-left: 2px solid $mainDarkGray;
  }
  .TitelYourExperience {
    text-align: right;
  }
}
