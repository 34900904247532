@import "../../../../utils/variables/variables.scss";
.modal {
  width: 50vw;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  .close {
    width: 18px;
    position: absolute;
    right: 25px;
    top: 30px;
    cursor: pointer;
  }
}
.modal:focus {
  outline: none !important;
}
.containerGridAll {
  // background-color: $subColorBlue;
  background: url("../../../../img/bg-gradient-popup.jpg");
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  border-radius: 6px;
  -webkit-border-radius: 6px;
  -moz-border-radius: 6px;
  -ms-border-radius: 6px;
  -o-border-radius: 6px;
  overflow: hidden;
  margin-bottom: 50px;
  .containerImgRight {
    img {
      width: 100%;
      max-height: 240px;
      object-fit: cover;
      object-position: center;
    }
  }
  .containerData {
    padding: 50px 24px;
    position: relative;
    &:after {
      content: "";
      background: url("../../../../img/mask-snow-popup.png");
      background-repeat: no-repeat;
      background-position: center;
      background-size: cover;
      position: absolute;
      bottom: 0;
      width: 100%;
      height: 39px;
      left: 0;
    }
    .bookBtn a {
      // font-size: 18px;
      // padding: 15px 12px;
      //Coming soon
      padding: 18px 12px;
      font-size: 12px;
      pointer-events: none;
      //Coming soon
      font-family: $DIN-bold;
      text-decoration: none;
      color: $white;
      display: inline-block;
      text-align: center;
      width: 100%;
      border-radius: $Radius;
      border: solid 2px;
      transition: all ease 0.2s;
      margin-top: 25px;
    }
    .bookBtn a:hover {
      background-color: $white;
      color: $subColorBlue;
    }
    p,
    ul {
      margin: 0;
      color: $white;
    }
    .Header {
      margin-bottom: 10px;
      p {
        font-size: 28px;
        line-height: 37px;
        font-family: $FBT-heavy;
        margin-bottom: 15px;
        // text-transform: uppercase;
      }
    }
    .description {
      a {
        font-size: 14px !important;
        font-family: $DIN-bold;
        text-decoration: none;
        color: $white;
        display: inline-block;
        text-align: center;
        width: 97%;
        padding: 12px;
        border-radius: $Radius;
        border: solid 2px;
        transition: all ease 0.2s;
        margin-top: 5px;
      }
      a:hover {
        background-color: $white;
        color: $subColorBlue;
      }
      p {
        font-size: 20px;
        font-family: $DIN-regular;
        margin-bottom: 15px;
        line-height: 28px;
        // margin-bottom: 15px;
        display: inline-block;
      }
      ul {
        list-style: none;
        padding: 0 0 0 18px;
        margin-bottom: 18px;
        li {
          font-size: 18px;
          line-height: 20px;
          font-family: $DIN-regular;
          margin-bottom: 8px;
          &::before {
            content: "";
            display: inline-block;
            border: 1px solid $white;
            margin-left: -1em;
            margin-right: 10px;
            width: 5px;
          }
        }
      }
      .listHeader {
        margin-bottom: 20px;
      }
    }
    .PackagePerPerson {
      .Header {
        p {
          font-size: 20px;
          line-height: 30px;
          margin: 0;
          // text-transform: uppercase;
        }
        span {
          font-family: $DIN-regular;
        }
        a {
          display: inline-block;
          text-align: center;
          background: #fff;
          border-radius: 5px;
          color: #4a6ee8;
          text-decoration: none;
          font-family: "D-DIN-Bold";
          padding: 18px 50px;
          font-size: 20px;
          margin-top: 8vh;
          border: none;
        }
      }
      .description {
        text-align: center;
        p {
          font-size: 38px;
          font-family: $FBT-heavy;
          line-height: 60px;
          color: $white;
          span {
            font-size: 18px;
            font-family: $FBT-medium;
            line-height: 24px;
          }
        }
        .OR {
          font-size: 28px;
          font-family: $FBT-heavy;
          line-height: 42px;
        }
        > div {
          > div {
            text-align: initial;
          }
        }
      }
    }
    .header2 {
      text-align: center;
      margin-bottom: 22px;
      h2 {
        font-size: 28px;
        line-height: 37px;
        font-family: "D-DIN-Bold";
        margin: 0 0 5px 0;
        text-transform: uppercase;
        color: $white;
      }
      p {
        font-size: 18px;
        line-height: 24px;
        font-family: $DIN-regular;
        margin: 0;
        text-align: left;
        white-space: break-spaces;
      }
    }
  }
}
.containerGridAll:last-child {
  margin-bottom: 0;
}
@media only screen and (min-width: 1280px) {
  .containerGridAll {
    .containerImgRight {
      img {
        min-height: 100%;
        object-fit: cover;
      }
    }
    .containerData {
      padding: 36px 24px;
      min-height: 380px;
      p,
      ul {
        margin: 0;
      }
    }
    .containerHappy {
      padding: 100px 24px !important;
    }
  }
}
@media screen and (max-width: 1199px) {
  .modal {
    width: 100vw !important;
  }
  .PackagePerPerson {
    .Header {
      p {
        font-size: 17px !important;
      }
    }
    .description {
      p {
        font-size: 30px !important;
        line-height: 45px !important;
      }
    }
  }
  .containerImgRight {
    img {
      min-height: 280px;
    }
  }
  .containerData {
    & > .Header {
      p {
        font-size: 24px !important;
      }
    }
    .description {
      p {
        font-size: 18px !important;
      }
    }
  }
}
html[lang="ar"] {
  .containerData {
    text-align: right;
    direction: rtl;
    li {
      margin-right: 20px;
      position: relative;
    }
    li:before {
      position: absolute;
      right: -30px;
      top: 10px;
    }
  }
  // .PackagePerPerson{
  //     .description{
  //         & > div{
  //             justify-content: space-around
  //         }
  //     }
  // }
  .header2 {
    p {
      text-align: right !important;
    }
  }
}
