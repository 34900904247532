@import "../../../../utils/variables/variables.scss";
.contactUsContainer {
  .btnContact {
    background-color: $mainRed;
    border: 1px solid $mainRed;
    color: $white;
    font-size: 17px;
    font-family: "Gotham-Bold";
    margin-top: 0px;
    padding: 8px 25px;
    &:hover {
      background-color: $white;
      border: 1px solid $mainRed;
      color: $mainRed;
    }
  }
}
