@import "../../../utils/variables/variables.scss";
.containerHeaderPanel {
  // max-height: 671px;
  position: relative;
  img {
    width:100%;
    object-fit: cover;
    // height: 100vh;
    
  }
  // top: 15px;
  .containerTitel {
    // background-color: $subwhite;
    padding: 25px;
    -webkit-backdrop-filter: blur(10px) opacity(0.98) brightness(0.8);
    backdrop-filter: blur(10px) opacity(0.98) brightness(0.8);
    // padding: 25px 16px;
    border-radius: 5px;
    -webkit-border-radius: 5px;
    -moz-border-radius: 5px;
    -ms-border-radius: 5px;
    -o-border-radius: 5px;
    // box-shadow: 2px 2px 2px #cccccc69;
    // position: relative;
    position: absolute;
    bottom: 0;
    border-radius: 12px 12px 0 0;
    p {
      margin: 0;
    }
    .subTitle {
      color: $white;
      font-size: 20px;
      font-family: $D-DIN-REGULAR;
      line-height: 21px;
    }
    .title {
      color: $white;
      font-size: 28px;
      font-family: "D-DIN-Bold";
      line-height: 50px;
    }
    .description {
      color: $white;
      font-size: 14px;
      font-family: $DIN-regular;
      line-height: 18px;
      margin-top: 5px;
    }
  }
}

.containerTextCenter {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  .title {
    color: $white;
    font-size: 30px;
    font-family: "D-DIN-Bold";
    line-height: 40px;
    text-align: center;
  }
}
.containerTextCenter p {
  font-family: $Cairo-bold !important;
}
@media only screen and (max-width: 620px) {
  .containerHeaderPanel {
    .containerTitel {
      margin-top: -10px;
    }
    .containerTextCenter {
      .title {
        font-size: 26px;
      }
      .titleCentered {
        transform: translateY(-80px);
        font-size: 40px !important;
        font-weight: 900 !important;
      }
    }
    .containerPanal {
      img {
        // height: auto;
        height: 100vh;
        object-fit: cover;
        object-position: 37%;
      }
    }
  }
}
@media only screen and (min-width: 1200px) {
  .containerHeaderPanel {
    .containerTitel {
      // padding: 50px 32px;
      // margin-top: -120px;
      padding: 45px 144px 70px;

      .subTitle {
        font-size: 20px;
        line-height: 31px;
      }
      .title {
        font-size: 36px;
        line-height: 60px;
      }
      .description {
        font-size: 18px;
        line-height: 26px;
      }
    }
  }
  .containerTextCenter {
    .title {
      font-size: 45px;
      line-height: 60px;
    }
    .titleCentered {
      transform: translateY(-40px);
      font-size: 60px !important;
      font-weight: 900 !important;
    }
  }
}
html[lang="ar"] {
  .containerTitel {
    text-align: right;
  }
  .description {
    direction: rtl;
  }
}
@media (max-width: 1199px) {
  .containerHeaderPanel {
    img {
      height: 100vh;
      object-position: 37%;
    }
  }
}
